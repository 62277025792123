import { Action, createReducer, on } from '@ngrx/store';
import * as TimelineNewsActions from '../../../actions/timeline-news.actions';
import { createEntityAdapter, EntityState } from '@ngrx/entity';
import * as fromMy7n from '../../index';
import { INewsBase, NEWS_PER_PAGE } from 'projects/my7n-app/src/interfaces/news';
import { INewsLoadedInfo } from '../../../../interfaces/timeline';

export const adapter = createEntityAdapter<INewsBase>({
  // we need to provide custom selectId function because standard one uses "id" instead of "Id" (like in our interface)
  selectId: (instance) => instance.Id as string
});

export interface FeatureState extends fromMy7n.State {
  news: TimelineNewsState;
}

export interface TimelineNewsState extends EntityState<INewsBase> {
  pageSize: number;
  scrollY: number;
  loading: boolean;
  loadingError: boolean;
  loadingMore: boolean;
  loadingMoreError: boolean;
  loadedInfo: INewsLoadedInfo;
  initialized: boolean;
}

export const initialState: TimelineNewsState = adapter.getInitialState({
  pageSize: NEWS_PER_PAGE,
  scrollY: 0,
  loading: false,
  loadingError: false,
  loadingMore: false,
  loadingMoreError: false,
  loadedInfo: {
    newsCmsLoadedCount: 0,
    newsCmsAllLoaded: false,
    newsYammerLoadedCount: 0,
    newsYammerAllLoaded: false,
    eventsLoadedCount: 0,
    eventsAllLoaded: false
  },
  initialized: false
});


const reducer = createReducer(
  initialState,
  on(TimelineNewsActions.queryNews, (state) => ({
    ...state,
    loading: true,
    loadingError: false,
    initialized: true
  })),
  on(TimelineNewsActions.queryNewsSuccess, (state, { news, loadedInfo }) => ({
    ...adapter.setMany(news, state),
    loadedInfo,
    loading: false,
    loadingError: false
  })),
  on(TimelineNewsActions.queryNewsError, (state) => ({
    ...state,
    loading: false,
    loadingError: true
  })),
  on(TimelineNewsActions.queryMoreNews, (state) => ({
    ...state,
    loadingMore: true,
    loadingMoreError: false
  })),
  on(TimelineNewsActions.queryMoreNewsSuccess, (state, { news, loadedInfo }) => ({
    ...adapter.setMany(news, state),
    loadingMore: false,
    loadingMoreError: false,
    loadedInfo
  })),
  on(TimelineNewsActions.queryMoreNewsError, (state) => ({
    ...state,
    loadingMore: false,
    loadingMoreError: true
  })),
  on(TimelineNewsActions.saveScrollYPosition, (state, { scrollY }) => ({
    ...state,
    scrollY
  })),
  on(TimelineNewsActions.clearNews, () => ({
    ...initialState
  }))
);

export function timelineNewsReducer(state: TimelineNewsState | undefined, action: Action) {
  return reducer(state, action);
}

// configure entity store selectors
export const {
  // select the array of news
  selectIds: selectNewsId,

  // select the dictionary of news entities
  selectEntities: selectNewsEntities,

  // selectAll
  selectAll: selectAllNews,

  // select total news count
  selectTotal: selectTotalLoadedNewsCount
} = adapter.getSelectors();

export const getNews = (state: TimelineNewsState) => {
  return state;
};

export const getLoadedInfo = (state: TimelineNewsState) => {
  return state.loadedInfo;
};

export const getPageSize = (state: TimelineNewsState) => {
  return state.pageSize;
};

export const getScrollY = (state: TimelineNewsState) => {
  return state.scrollY;
};

export const getLoading = (state: TimelineNewsState) => {
  return state.loading;
};

export const getLoadingMore = (state: TimelineNewsState) => {
  return state.loadingMore;
};

export const getLoadingError = (state: TimelineNewsState) => {
  return state.loadingError;
};

export const getLoadingMoreError = (state: TimelineNewsState) => {
  return state.loadingMoreError;
};

export const getInitialized = (state: TimelineNewsState) => {
  return state.initialized;
};
