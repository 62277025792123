import {ISharepointGallery} from '../../../interfaces/sharepoint';
import * as fromMy7n from '../index';
import {createEntityAdapter, EntityState} from '@ngrx/entity';
import {Action, createReducer, on} from '@ngrx/store';
import * as GalleryActions from '../../actions/galleries.actions';

export const galleriesFeatureKey = 'galleries';

export const adapter = createEntityAdapter<ISharepointGallery>({
  selectId: (instance) => instance.basePath
});

export interface FeatureState extends fromMy7n.State {
  galleries: GalleriesState;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface GalleriesState extends EntityState<ISharepointGallery> {}

export const initialState: GalleriesState = adapter.getInitialState();


const reducer = createReducer(
  initialState,
  on(GalleryActions.queryGalleryData, (state, { path }) => ({
    ...adapter.updateOne(
      {
        id: path,
        changes: { loading: true }
      },
      state
    )
  })),
  on(GalleryActions.queryGalleryDataError, (state, { path }) => ({
    ...adapter.updateOne(
      {
        id: path,
        changes: {
          loading: false,
          error: true
        }
      },
      state
    )
  })),
  on(GalleryActions.updateGalleryData, (state, { gallery }) => ({
    ...adapter.upsertOne(
      {
        ...gallery,
        lastRequestTimestamp: Date.now()
      },
      state
    )
  }))
);

export function galleriesReducer(state: GalleriesState | undefined, action: Action) {
  return reducer(state, action);
}


// configure entity store selectors
export const {
  // select the array of notifications
  selectIds: selectGalleriesIds,

  // Select the dictionary of notifications entities
  selectEntities: selectGalleriesEntities,

  // Select all entities
  selectAll: selectAllGalleries,

  // Select total count
  selectTotal: selectGalleriesTotalLoaded
} = adapter.getSelectors();
