import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IPresenter, IPrintPreferences, ITemplatesAndPresentersResponse } from '../interfaces/print-preferences';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';
import { lastValueFrom } from 'rxjs';
import { AppConfigService } from './app-config.service';

@Injectable()
export class PrintSettingsService {

  readonly API_CVS: string;
  readonly API_USER: string;

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService) {
    this.API_CVS = this.appConfigService.serviceUrl(ServiceNames.Core) + 'cvs/';
    this.API_USER = this.appConfigService.serviceUrl(ServiceNames.User, 'v1') + 'user/';
  }

    getPresentersAndTemplates(): Promise<ITemplatesAndPresentersResponse> {
        return lastValueFrom(this.http.get<ITemplatesAndPresentersResponse>(this.API_CVS + 'printout/templatesAndPresenters')).then((result) => {
            return result;
        }).catch((error) => {
            console.error('[PrintSettingsService] Failed to getPresentersAndTemplates', error);
            return Promise.reject(error);
        });
    }

    savePrintSettings(data: IPrintPreferences): Promise<any> {
        return lastValueFrom(this.http.put(this.API_USER + 'settings/print', data)).then((result) => {
            // reload config
            this.globalAppConfigFacadeService.updatePrintPreferences(data);
            return result;
        }, (error) => {
            console.error('[PrintSettingsService] Failed to save print settings: ', error);
            return Promise.reject(error);
        });
    }

    preparePresentersAndTemplates(context: any, printPreferences: IPrintPreferences) {
        context.printSettings = context.printSettings || {};
        context.presenters = context.presenters || {};
        context.templates = context.templates || {};

        return this.getPresentersAndTemplates().then((response: ITemplatesAndPresentersResponse) => {
            const noPresenter: IPresenter = {
                Id: null,
                CrmId: null,
                Name: "No presenter",
                PortraitUrl: null
            };
            context.Presenters = response.Presenters;
            context.Templates = response.Templates;
            // context = { ...context, ...response };

            // extend presenter by empty presenter
            context.Presenters.unshift(noPresenter);

            // set presenter from root as presenter in component

            for (let i = 0; i < context.Presenters.length; i++) {
                if (context.Presenters[i].CrmId === printPreferences.PrintCvPresenterId) {
                    context.presenters.choosenPresenter = context.Presenters[i];
                    break;
                }
            }

            if (context.presenters.choosenPresenter) {
                // set presenter id from dd
                context.printSettings.PrintCvPresenterId = context.presenters.choosenPresenter.CrmId;
            } else {
                console.error('[PrintSettingsService] Data inconsistency, preferred presenter ID does not exist in returned presenter list');
                context.printSettings.PrintCvPresenterId = noPresenter.CrmId;
                context.presenters.choosenPresenter = noPresenter;
            }

            // set template from root as presenter in component
            if (printPreferences.PrintCvTemplateId === 0 || printPreferences.PrintCvTemplateId === null) {
                context.templates.choosenTemplate = context.Templates[0];
            } else {
                context.Templates.forEach(element => {
                    if (element.Id === printPreferences.PrintCvTemplateId) {
                        context.templates.choosenTemplate = element;
                    }
                });
            }

            // set template id from dd
            context.printSettings.PrintCvTemplateId = context.templates.choosenTemplate.Id;
        });
    }

}
