import { Directive, Input, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthorizationService } from '../services/authorization.service';
import { map, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalAppConfigFacadeService } from '../services/facades/global-app-config-facade.service';

@Directive({
  selector: '[authCan]'
})
export class AuthCanDirective implements OnDestroy {
  @Input() set authCan(privilegeStr: string) {
    this.doCheck(privilegeStr);
  }

  /**
   * Indicates the mode of checking privileges.
   * If true, user has no access if has any of given privileges.
   *
   * @type {boolean}
   */
  private authCanNot = false;

  private destroySubject$ = new Subject<void>();

  constructor(private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef,
              private authorizationService: AuthorizationService,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService) {
  }

  /**
   * Sets authCanNot value.
   *
   * @param {boolean} value
   */
  public setAuthCanNot(value: boolean) {
    this.authCanNot = value;
  }

  /**
   * Checks if user has privilege to see DOM element.
   * If not, component template is cleared.
   *
   * @param {string} privilege Privilege name.
   */
  protected doCheck(privilege: string) {
    if (!privilege) {
      console.error(`[AuthCanDirective] *authCan${this.authCanNot ? 't' : ''} attribute is invalid`);
      return;
    }

    this.authorizationService.can(privilege).pipe(
      takeUntil(this.destroySubject$),
      map((authTest) => this.authCanNot ? !authTest : authTest),
      withLatestFrom(this.globalAppConfigFacadeService.user$)
    ).subscribe(([authTest, user]) => {
      if (authTest) {
        // If condition is true add template to DOM
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        // Else remove template from DOM
        this.viewContainer.clear();
        console.debug(`[AuthCanDirective] ${user.FirstName} ${user.LastName} has no access to ${privilege}`);
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }

}
