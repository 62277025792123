import { Inject, Injectable, Injector, NgZone, OnDestroy } from '@angular/core';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { IMy7nEnvConfig, SeverityLevel } from '../interfaces/my7n-env-config';
import { EnvironmentService } from './environment.service';
import { ApplicationInsightsService } from './application-insights.service';

@Injectable()
export class LogService implements OnDestroy {
  static logDepthLevel = 3;

  private overridePerformed = false;
  private recurrenceCallPrevention: any = {};
  private readonly realConsole: any = {};
  private readonly manuallyOverriddenMethods: Array<string> = ['error', 'warn', 'log', 'debug'];

  constructor(private injector: Injector,
              private ngZone: NgZone,
              private environment: EnvironmentService,
              @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig) {

    /**
     * Instance of this service is created in main app module as dependency for initAll function
     * when no other component requires it as a dependency.
     * This ensures replacing the console methods on prod env.
     */

    ngZone.runOutsideAngular(() => {
      this.manuallyOverriddenMethods.forEach((method) => {
        this.realConsole[method] = window.console[method];
        this.recurrenceCallPrevention[method] = false;
      });

      if (environment.production) {
        this.overrideWindowConsole();
      }
    });
  }

  /**
   * Used to map given arguments into strings (almost like JSON.stringify).
   * Depth is set as -1 by default which will recurrently go as deep as possible
   * (WARN, may cause infinite execution when circular references will exist)
   * Depth >-1 will go into the given arguments for given depth.
   * @param arg
   * @param depth how deep go to combine the message
   */
  static mapArg(arg: any, depth: number = -1) {
    if (arg === undefined) {
      return 'undefined';
    }

    if (arg === null) {
      return 'null';
    }

    if (arg instanceof Error) {
      return `Error(${arg.message})`;
    }

    if (arg instanceof Date) {
      return `Date(${arg.toISOString()})`;
    }

    if (typeof arg === 'bigint') {
      return arg.toString() + 'n';
    }

    if (Array.isArray(arg)) {
      if (depth === 0) {
        return '[ ... ]';
      }
      return `[${arg.map((value) => {
        return LogService.mapArg(value, depth - 1);
      }).join(', ')}]`;
    }

    if (typeof arg === 'object' && !Array.isArray(arg)) {
      let constructorName = '';

      // Square brackets property get because it does not exist in IE11
      if (arg.constructor && arg.constructor['name'] && arg.constructor['name'] !== 'Object') {
        constructorName = arg.constructor['name'];
      }

      if (depth === 0) {
        return constructorName + '{ ... }';
      }
      return `${constructorName}{${Object.keys(arg).map((key) => {
        return `'${key}': ${LogService.mapArg(arg[key], depth - 1)}`;
      }).join(', ')}}`;
    }

    return arg.toString();
  }

  static limitedMapArg(arg) {
    return LogService.mapArg(arg, LogService.logDepthLevel);
  }

  private insightsError(...args) {
    if (this.envConfig.INSIGHTS_VERBOSITY_LEVEL > SeverityLevel.Error) {
      return;
    }
    const appInsightsService = this.injector.get(ApplicationInsightsService);
    this.recurrenceCallPrevention.error = true;
    appInsightsService.trackException(new Error(args.map(LogService.limitedMapArg).join(', ')), null, SeverityLevel.Error);
  }

  private insightsWarn(...args) {
    if (this.envConfig.INSIGHTS_VERBOSITY_LEVEL > SeverityLevel.Warning) {
      return;
    }
    const appInsightsService = this.injector.get(ApplicationInsightsService);
    this.recurrenceCallPrevention.warn = true;
    appInsightsService.trackException(new Error(args.map(LogService.limitedMapArg).join(', ')), null, SeverityLevel.Warning);
  }

  private insightsLog(...args) {
    if (this.envConfig.INSIGHTS_VERBOSITY_LEVEL > SeverityLevel.Information) {
      return;
    }
    const appInsightsService = this.injector.get(ApplicationInsightsService);
    this.recurrenceCallPrevention.log = true;
    appInsightsService.trackTrace(args.map(LogService.limitedMapArg).join(', '));
  }

  private insightsDebug(...args) {
    if (this.envConfig.INSIGHTS_VERBOSITY_LEVEL > SeverityLevel.Verbose) {
      return;
    }
    const appInsightsService = this.injector.get(ApplicationInsightsService);
    this.recurrenceCallPrevention.debug = true;
    appInsightsService.trackTrace(args.map(LogService.limitedMapArg).join(', '));
  }

  private overrideWindowConsole() {
    const consoleMethods = Object.keys(this.realConsole).filter((methodName) => {
        return this.manuallyOverriddenMethods.indexOf(methodName) === -1;
      }),
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      noop = function () {};

    consoleMethods.forEach((methodName) => {
      window.console[methodName] = noop;
    });

    window.console.error = this.error.bind(this);
    window.console.warn = this.warn.bind(this);
    window.console.log = this.log.bind(this);
    window.console.debug = this.debug.bind(this);

    this.overridePerformed = true;
  }

  private restoreWindowConsole() {
    Object.keys(this.realConsole).forEach((methodName: string) => {
      window.console[methodName] = this.realConsole[methodName];
    });
  }

  error (...args) {
    if (!this.recurrenceCallPrevention.error) {
      this.insightsError(...args);
    }

    this.recurrenceCallPrevention.error = false;

    if (this.envConfig.LOG_VERBOSITY_LEVEL <= SeverityLevel.Error) {
      // eslint-disable-next-line prefer-spread
      this.realConsole.error.apply(this.realConsole, args);
    }
  }

  warn (...args) {
    if (!this.recurrenceCallPrevention.warn) {
      this.insightsWarn(...args);
    }

    this.recurrenceCallPrevention.warn = false;

    if (this.envConfig.LOG_VERBOSITY_LEVEL <= SeverityLevel.Warning) {
      this.realConsole.warn.apply(this.realConsole, args);
    }
  }

  log (...args) {
    if (!this.recurrenceCallPrevention.log) {
      this.insightsLog(...args);
    }

    this.recurrenceCallPrevention.log = false;

    if (this.envConfig.LOG_VERBOSITY_LEVEL <= SeverityLevel.Information) {
      this.realConsole.log.apply(this.realConsole, args);
    }
  }

  debug (...args) {
    if (!this.recurrenceCallPrevention.debug) {
      this.insightsDebug(...args);
    }

    this.recurrenceCallPrevention.debug = false;

    if (this.envConfig.LOG_VERBOSITY_LEVEL <= SeverityLevel.Verbose) {
      this.realConsole.debug.apply(this.realConsole, args);
    }
  }

  // This probably won't happen
  // but in case it does, it should restore proper console methods
  // (used in tests to teardown after each test)
  ngOnDestroy() {
    if (this.overridePerformed) {
      this.restoreWindowConsole();
    }
  }
}
