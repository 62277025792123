import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import { ResolveDecorator } from '../classes/resolve-decorator';
import { Observable, catchError, concatMap, map, of } from 'rxjs';
import { TermsService } from '../services/terms.service';
import { GlobalAppConfigFacadeService } from '../services/facades/global-app-config-facade.service';
import { TermsTypes, ITermsContentResponse } from '../interfaces/terms';

@Injectable()
export class TermsResolver {
  constructor(
    private router: Router,
    private globalAppConfigFacade: GlobalAppConfigFacadeService,
    private termsService: TermsService
  ) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<ITermsContentResponse> {
    return this.globalAppConfigFacade
      .user$
      .pipe(
        map((user) => [user.AcceptStoringAndSendingDataBy7n, user.My7nConsents]),
        catchError((error) => {
          console.error(
            '[TermsResolver] There was a problem while getting user',
            error
          );
          ResolveDecorator.redirectToErrorPage(error, this.router);
          return of(error);
        }),
        concatMap(([AcceptStoringAndSendingDataBy7n, My7nConsents]: [boolean, boolean]) => {
          let termsType: TermsTypes;

          if(!AcceptStoringAndSendingDataBy7n && !My7nConsents) {
            termsType = TermsTypes.full;
          }

          if(AcceptStoringAndSendingDataBy7n && !My7nConsents) {
            termsType = TermsTypes.partial;
          }

          return this.termsService.getTermsContent(termsType).pipe(map((response) => response.TermsContent));
        }),
        catchError((error) => {
          console.error(
            '[TermsResolver] There was a problem while getting terms content',
            error
          );
          ResolveDecorator.redirectToErrorPage(error, this.router);
          return of(error);
        }),
      );
  }
}
