import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef
} from '@angular/core';
import { SnackBarService, SnackBarTypes } from '@my7n/ui';
import { default as moment } from 'moment';
import { UserService } from '../../../services/user.service';

const DAILY_TRANSFER_REQUEST_STORAGE_NAME = 'daily-transfer-request';

@Component({
  selector: 'request-transfer',
  templateUrl: './request-transfer.component.html',
  styleUrls: ['./request-transfer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RequestTransferComponent {
  requestPending = false;

  get requestAlreadySentToday() {
    const lastTransferRequestDate = localStorage?.getItem(
      DAILY_TRANSFER_REQUEST_STORAGE_NAME
    ) as string;
    let nextRequestAvailabilityDate: moment.Moment;
    let currentDate: moment.Moment;

    if (lastTransferRequestDate) {
      currentDate = moment();
      nextRequestAvailabilityDate = moment(lastTransferRequestDate)
        .add(1, 'day')
        .hour(0)
        .minute(0)
        .seconds(0)
        .millisecond(0);

      return currentDate.isBefore(nextRequestAvailabilityDate);
    } else {
      return false;
    }
  }

  constructor(
    private userService: UserService,
    private snackBarService: SnackBarService,
    private cdr: ChangeDetectorRef
  ) {}

  requestTransfer() {
    this.requestPending = true;
    this.userService.requestTransfer().subscribe({
      next: () => {
        this.requestTransferSuccess();
      },
      error: () => {
        this.requestTransferError();
      }
    });
  }

  private setTransferRequestTimeInLocalStorage() {
    const currentDate = moment();
    localStorage?.setItem(
      DAILY_TRANSFER_REQUEST_STORAGE_NAME,
      currentDate.toISOString()
    );
  }

  private requestTransferSuccess() {
    this.requestPending = false;
    this.cdr.markForCheck();
    this.setTransferRequestTimeInLocalStorage();
  }

  private requestTransferError() {
    this.requestPending = false;
    this.cdr.markForCheck();
    this.snackBarService.open({
      message: 'Failed to send transfer request.',
      type: SnackBarTypes.ErrorAlt
    });
  }
}
