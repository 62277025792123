import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  HostListener
} from '@angular/core';
import { Observable, Subject, Subscription } from 'rxjs';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import {
  animate,
  state,
  style,
  transition,
  trigger
} from '@angular/animations';
import { Icons } from '@my7n/ui';

// Config
import { environment } from '../../environments/environment';
// Services
import { NavigationService } from '../../services/navigation.service';
import { AuthorizationService } from '../../services/authorization.service';
// Interfaces
import { IUserPreferences } from '../../interfaces/user-preferences';
import { INotificationItem } from '../../interfaces/notification-item';
import { INavigationItem } from '../../interfaces/navigation-item';
// Facades
import { NotificationsFacade } from '../../services/facades/notifications-facade.service';
// Features
import { NotificationsFeatures } from '@my7n/features';
import { GlobalAppConfigFacadeService } from '../../services/facades/global-app-config-facade.service';

@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('openCloseNav', [
      state('opened', style({ height: '*' })),
      state('closed', style({ height: 0 })),
      transition('opened <=> closed', animate('.3s ease-in-out'))
    ])
  ]
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild('navigationMain') navigationMain: ElementRef;
  @ViewChild('navbarOptions', { static: true }) navbarOptions: ElementRef;
  @ViewChild('userSelectBox', { static: true }) userSelectBox: ElementRef;
  @ViewChild('navigationButton', { static: true }) navigationButton: ElementRef;
  @ViewChild('navigationContainer', { static: true })
  navigationContainer: ElementRef;

  @HostListener('window:click', ['$event'])
  handleClick(e: Event) {
    const clickedInside = this.navigationMain?.nativeElement.contains(e.target);
    if (!clickedInside) {
      this.navService.updateNavigationDropdownVisibility(false);
      this.cdr.markForCheck();
    }
  }

  currentUser: IUserPreferences;
  notifications$: Observable<Array<INotificationItem>>;
  totalUnseenNotifications$: Observable<number>;
  notificationsRequestTimestamp$: Observable<Date>;
  navigationOpened: boolean;
  DEV_ENV = !environment.production;

  activeLink: INavigationItem;

  ICONS = Icons;

  private configChangesSubscription: Subscription;
  private unsubscribe$: Subject<void> = new Subject<void>();

  get instantNotificationFeature() {
    return NotificationsFeatures.Default;
  }

  user$ = this.globalAppConfigFacadeService.user$;

  constructor(
    private navService: NavigationService,
    private globalAppConfigFacadeService: GlobalAppConfigFacadeService,
    private authorizationService: AuthorizationService,
    private notificationsFacade: NotificationsFacade,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.globalAppConfigFacadeService.user$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        this.currentUser = user;
        this.cdr.markForCheck();
      });

    this.user$
      .pipe(
        filter((user) => !!user),
        switchMap(() => {
          return this.authorizationService.can(this.instantNotificationFeature);
        })
      )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((hasAccess) => {
        if (hasAccess) {
          this.notificationsFacade.queryNotifications();
          this.notifications$ = this.notificationsFacade.notifications$;
          this.totalUnseenNotifications$ =
            this.notificationsFacade.totalUnseenNotifications$;
          this.notificationsRequestTimestamp$ =
            this.notificationsFacade.notificationsRequestTimestamp$;
          this.cdr.markForCheck();
        }
      });

    // We could pass the observable to element, but some usages are hidding and showing
    // subscription (with async pipe) with ngIf which causes few blinks of content in that part
    // this ensures less subscription/unsubscription calls
    this.navService.activeNavigationItem$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((navItem) => {
        this.activeLink = navItem;
        this.cdr.markForCheck();
      });

    this.navService.activeNavigationItem$
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((item: INavigationItem) => item === null)
      )
      .subscribe(() => {
        // for error pages use this mock just to show the navigation
        this.activeLink = {
          title: 'Home',
          link: '/'
        };
      });

    this.navService.navDropdownVisible$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((opened) => {
        this.navigationOpened = opened;
        this.cdr.markForCheck();
      });
  }

  pageInIframe(): boolean {
    try {
      return window.self !== window.top;
    } catch {
      return true;
    }
  }

  toggleNavigation() {
    this.navService.updateNavigationDropdownVisibility(!this.navigationOpened);
    this.cdr.markForCheck();
  }

  ngOnDestroy() {
    if (this.configChangesSubscription) {
      this.configChangesSubscription.unsubscribe();
    }
    this.unsubscribe$.next();
  }
}
