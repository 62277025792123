export enum TermsTypes {
  full = 'f',
  partial = 'p'
}

export interface ITermsContent {
  Header: string;
  Sections: Array<ITermsSection>;
  Checkboxes: Array<ITermsCheckbox>;
  Version: string;
}

interface ITermsSection {
  Header: string;
  Paragraphs: Array<string>;
}

interface ITermsCheckbox {
  Name: string;
  Label: string;
  Mandatory: boolean;
  MandatoryText?: string;
  SmallText?: string;
}

export interface ITermsContentResponse {
  TermsContent: ITermsContent;
}
