import { Component, OnInit } from '@angular/core';
import { UserProfileService } from '../../services/user-profile.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, UntypedFormGroup, Validators } from '@angular/forms';
import { ITermsContent } from '../../interfaces/terms';

@Component({
  selector: 'terms-agreement',
  templateUrl: './terms-agreement.component.html',
  styleUrls: ['./terms-agreement.component.scss']
})

export class TermsAgreementComponent implements OnInit {
  loading = false;
  error = false;
  termsForm: UntypedFormGroup;
  termsContent: ITermsContent;

  constructor(
    private userProfileService: UserProfileService,
    private router: Router,
    private route: ActivatedRoute) {
  }

  ngOnInit() {
    this.termsContent = this.route.snapshot.data.termsContent;
    this.createTermsForm();
  }

  sendAgreement(form: UntypedFormGroup) {
    this.setServerErrorFlag(false);

    form.markAllAsTouched();

    if (form.invalid) {
      console.debug('[TermsAgreementComponent] You need to agree to the terms of use', form.value.AcceptStoringAndSendingDataBy7n);
      return false;
    }

    this.loading = true;

    this.userProfileService.updateTermsAgreement(form.value).then(() => {
      this.router.navigateByUrl('').then(() => {
        this.loading = false;
      });
    }).catch((e) => {
      this.setServerErrorFlag(true);
      this.loading = false;
    });
  }

  setServerErrorFlag(flag: boolean): void {
    this.error = flag;
    if (flag) {
      this.termsForm.setErrors({ serverError: true });
    } else {
      this.termsForm.setErrors(null);
    }
  }

  private createTermsForm() {
    this.termsForm = new FormGroup({});

    this.termsContent.Checkboxes.forEach((checkbox) => {
      this.termsForm.addControl(
        checkbox.Name,
        new FormControl(false, {
          validators: checkbox.Mandatory ? [Validators.requiredTrue] : null
        })
      );
    });
  }
}
