import { Directive, Input, TemplateRef, ViewContainerRef, OnDestroy } from '@angular/core';
import { AuthorizationService } from "../services/authorization.service";
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Directive({
  selector: '[authCanSome]'
})
export class AuthCanSomeDirective implements OnDestroy {
  @Input() set authCanSome(privilegeStr: string) {
    this.doCheck(privilegeStr);
  }

  /**
   * Indicates the mode of checking privileges.
   * If true, user has no access if has any of given privileges.
   *
   * @type {boolean}
   */
  private authCanNot = false;

  private destroySubject$: Subject<void> = new Subject<void>();

  constructor(private templateRef: TemplateRef<unknown>,
              private viewContainer: ViewContainerRef,
              private authorizationService: AuthorizationService) {
  }

  /**
   * Sets authCanNot value.
   *
   * @param {boolean} value
   */
  public setAuthCanNot(value: boolean) {
    this.authCanNot = value;
  }

  /**
   * Checks if user has privileges to see DOM element.
   * If not, component template is cleared.
   *
   * @param {string} privilegeStr List of privileges separated by comma.
   */
  protected doCheck(privilegeStr: string) {
    if (!privilegeStr) {
      console.error(`[AuthCanSomeDirective] *authCan${this.authCanNot ? 't' : ''}Some attribute is invalid`);
      return;
    }

    const privileges: string[] = privilegeStr.trim().split(',');

    this.authorizationService.canSome(privileges).pipe(
      takeUntil(this.destroySubject$),
      map((authTest) => this.authCanNot ? !authTest : authTest)
    ).subscribe((authTest) => {
      if (authTest) {
        // add template to DOM
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        // Else remove template from DOM
        this.viewContainer.clear();
        console.debug(`[AuthCanSomeDirective] User has no permission to view elements with *authCan${this.authCanNot ? 't' : ''}Some="${privilegeStr}"`);
      }
    });
  }

  ngOnDestroy() {
    this.destroySubject$.next();
  }

}
