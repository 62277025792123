import { AbstractControl } from '@angular/forms';

// is more restrictive than built into angular validator
export const EmailValidator = (control: AbstractControl) => {
    // eslint-disable-next-line no-useless-escape
    const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!EMAIL_REGEXP.test(control.value)) {
        return {
            emailNotValid: true
        };
    }
    return null;
};
