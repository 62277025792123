import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthenticationService } from '../services/authentication.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Injectable()
export class UnauthorizedHttpInterceptor implements HttpInterceptor {
  constructor(
    private auth: AuthenticationService,
    private oauth: OidcSecurityService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: any) => {
        if (
          err instanceof HttpErrorResponse &&
          req.url.startsWith('https://api') &&
          err.status === 401
        ) {
          return this.oauth.isAuthenticated().pipe(
            switchMap((isAuthenticated: boolean) => {
              if (!isAuthenticated) {
                // if not authenticated, try to login
                this.auth.init();
              }
              return throwError(() => new HttpErrorResponse(err));
            })
          );
        }
        return throwError(() => err);
      })
    );
  }
}
