import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { My7nAppModule } from './my7n-app.module';
import { environment } from './environments/environment';
import { Workbox } from 'workbox-window';
import { generateInt } from './utils/generate-utils';

if (environment.production) {
  enableProdMode();
}

const isIE = window.document && !!window.document['documentMode'];

// check if the app is in the maintenance mode
// maintenance.html has got meta with name = maintenance and content = true
// if the maintenance mode is ON, then every document will have maintenance content
// we need to fetch something small that is excluded from the Service Worker pre-cache (like health-check.html)
fetch(
  '/health-check.html?hash=' + generateInt(1, 999999999999999), // we need to add this param to bypass browsers' cache
  { cache: 'no-store' }
)
  .then((response) => {
    return response.text();
  })
  .then((html) => {
    // Convert the HTML string into a document object
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const content = (doc?.querySelector('meta[name="maintenance"]') as any)?.content;
    if (content === 'true') {
      console.warn('Maintenance mode is ON - we will be back soon 🧐');
      location.replace('/maintenance.html');
    }
  })
  .catch((err) => {
    console.error('An error occurred during the health check: ', err);
  });

function loadServiceWorker() {
  // if (environment.production && ('serviceWorker' in navigator)) {
  if ('serviceWorker' in navigator) {
    const wb = new Workbox('ngsw-worker.js');

    // wb.addEventListener('activated', (event) => {
    //   if (!event.isUpdate) {
    //     // Service worker activated for the first time!
    //   } else {
    //     // Service worker activated!
    //   }
    // });

    // wb.addEventListener('waiting', (event) => {
    //   /*
    //   A new service worker has installed, but it can't activate
    //       until all tabs running the current version have fully unloaded.
    //       */
    // });

    // wb.addEventListener('installed', (event) => {
    //   if (!event.isUpdate) {
    //     // Service worker installed for the first time
    //   } else {
    //     // New content is available, reload is needed
    //   }
    // });

    wb.register();
  }
}

if (!isIE) {
  platformBrowserDynamic()
    .bootstrapModule(My7nAppModule)
    .then(() => {
      if(environment.enableServiceWorker) {
        loadServiceWorker();
      }
    })
    .catch((err) => {
      if (err && err.unauthorized) {
        return;
      }
      console.error('[bootstrapModule] error occurred', err);
    });
} else {
  console.error(
    "[bootstrapModule] Internet Explorer detected, App won't start"
  );
}
