import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  map,
  repeat,
  switchMap
} from 'rxjs/operators';
import {
  IBusinessDetails,
  IConsultantsTenantDetails,
  IPersonalDetails,
  ITermsAndConditions
} from '../../interfaces/user-profile';
import { UserProfileService } from '../../services/user-profile.service';
import { SnackBarService, SnackBarTypes, Icons } from '@my7n/ui';
import * as UserProfileActions from '../../store/actions/user-profile.actions';
import { ILocation } from '../../interfaces/location';
import {
  INotificationSettings,
  NotificationFrequency
} from '../../interfaces/notification-settings';
import { NotificationsService } from '../../services/notifications.service';
import { AppConfigService } from '../../services/app-config.service';
import * as fromUserProfile from '../reducers/user-profile/index';
import * as ngrxUtils from '../../utils/ngrx-utils';
import { UserService } from '../../services/user.service';
import { GlobalAppConfigFacadeService } from '../../services/facades/global-app-config-facade.service';

@Injectable()
export class UserProfileEffects {
  constructor(
    private actions$: Actions,
    private userProfileService: UserProfileService,
    private userService: UserService,
    private notificationsService: NotificationsService,
    private snackBarService: SnackBarService,
    private appConfigService: AppConfigService,
    private globalAppConfigFacadeService: GlobalAppConfigFacadeService,
    private store$: Store<fromUserProfile.FeatureState>
  ) {}

  queryPersonalDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileActions.queryPersonalDetails),
      concatLatestFrom(() => [
        this.store$.select(fromUserProfile.selectPersonalDetails),
        this.store$.select(
          fromUserProfile.selectPersonalDetailsRequestTimestamp
        )
      ]),
      distinctUntilChanged(),
      switchMap(([action, state, lastRequestTimestamp]) => {
        if (state !== null && ngrxUtils.isInCacheLimit(lastRequestTimestamp)) {
          return of(UserProfileActions.personalDetailsLoadedFromCache());
        } else {
          return this.userProfileService.getPersonalDetails().pipe(
            map((res: IPersonalDetails) => {
              return UserProfileActions.personalDetailsLoaded({
                personalDetails: res
              });
            }),
            catchError((err) => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Personal Details data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(UserProfileActions.queryPersonalDetailsError());
            })
          );
        }
      })
    );
  });

  queryBusinessDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileActions.queryBusinessDetails),
      concatLatestFrom(() => [
        this.store$.select(fromUserProfile.selectBusinessDetails),
        this.store$.select(
          fromUserProfile.selectBusinessDetailsRequestTimestamp
        )
      ]),
      distinctUntilChanged(),
      switchMap(([action, state, lastRequestTimestamp]) => {
        if (state !== null && ngrxUtils.isInCacheLimit(lastRequestTimestamp)) {
          return of(UserProfileActions.businessDetailsLoadedFromCache());
        } else {
          return this.userProfileService.getBusinessDetails().pipe(
            map((res: IBusinessDetails) => {
              return UserProfileActions.businessDetailsLoaded({
                businessDetails: res
              });
            }),
            catchError((err) => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Business Details data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(UserProfileActions.queryBusinessDetailsError());
            })
          );
        }
      })
    );
  });

  queryPreferredWorkLocations$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileActions.queryPreferredWorkLocations),
      concatLatestFrom(() => [
        this.store$.select(fromUserProfile.selectPreferredWorkLocations),
        this.store$.select(
          fromUserProfile.selectPreferredWorkLocationsRequestTimestamp
        )
      ]),
      distinctUntilChanged(),
      switchMap(([action, state, lastRequestTimestamp]) => {
        if (state !== null && ngrxUtils.isInCacheLimit(lastRequestTimestamp)) {
          return of(UserProfileActions.preferredWorkLocationsLoadedFromCache());
        } else {
          return this.userProfileService.getPreferredWorkLocations().pipe(
            map((res: Array<ILocation>) => {
              return UserProfileActions.preferredWorkLocationsLoaded({
                preferredWorkLocations: res
              });
            }),
            catchError((err) => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Preferred Work Locations data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(UserProfileActions.queryPreferredWorkLocationsError());
            })
          );
        }
      })
    );
  });

  queryTermsAndConditions$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileActions.queryTermsAndConditions),
      concatLatestFrom(() => [
        this.store$.select(fromUserProfile.selectTermsAndConditions),
        this.store$.select(
          fromUserProfile.selectTermsAndConditionsRequestTimestamp
        )
      ]),
      distinctUntilChanged(),
      switchMap(([action, state, lastRequestTimestamp]) => {
        if (state !== null && ngrxUtils.isInCacheLimit(lastRequestTimestamp)) {
          return of(UserProfileActions.termsAndConditionsLoadedFromCache());
        } else {
          return this.userProfileService.getTermsAndConditions().pipe(
            map((res: ITermsAndConditions) => {
              return UserProfileActions.termsAndConditionsLoaded({
                termsAndConditions: res
              });
            }),
            catchError((err) => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Terms and Conditions data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(UserProfileActions.queryTermsAndConditionsError());
            })
          );
        }
      })
    );
  });

  queryNotificationSettings$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileActions.queryNotificationSettings),
      concatLatestFrom(() => [
        this.store$.select(fromUserProfile.selectNotificationSettings),
        this.store$.select(
          fromUserProfile.selectNotificationSettingsRequestTimestamp
        )
      ]),
      distinctUntilChanged(),
      switchMap(([action, state, lastRequestTimestamp]) => {
        if (state !== null && ngrxUtils.isInCacheLimit(lastRequestTimestamp)) {
          return of(UserProfileActions.notificationSettingsLoadedFromCache());
        } else {
          return this.notificationsService.getUserNotificationSettings().pipe(
            map((res: INotificationSettings) => {
              return UserProfileActions.notificationSettingsLoaded({
                notificationSettings: {
                  ...res,
                  SummaryNotificationFrequency: Object.values(
                    NotificationFrequency
                  )[res.SummaryNotificationFrequency] // map index from the response to enum value
                }
              });
            }),
            catchError((err) => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Notification Settings data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(UserProfileActions.queryNotificationSettingsError());
            })
          );
        }
      })
    );
  });

  queryConsultantsTenantDetails$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileActions.queryConsultantsTenantDetails),
      concatLatestFrom(() => [
        this.store$.select(fromUserProfile.selectConsultantsTenantDetails),
        this.store$.select(
          fromUserProfile.selectConsultantsTenantDetailsRequestTimestamp
        )
      ]),
      distinctUntilChanged(),
      switchMap(([action, state, lastRequestTimestamp]) => {
        if (state !== null && ngrxUtils.isInCacheLimit(lastRequestTimestamp)) {
          return of(
            UserProfileActions.consultantsTenantDetailsLoadedFromCache()
          );
        } else {
          return this.userService.getConsultantsTenantDetails().pipe(
            map((res: IConsultantsTenantDetails) => {
              return UserProfileActions.consultantsTenantDetailsLoaded({
                details: res
              });
            }),
            catchError((err) => {
              this.snackBarService.open({
                message:
                  'An error occurred during loading Consultsnts tenant Details data.',
                type: SnackBarTypes.ErrorAlt,
                actionIcon: Icons.CLOSE_TINY
              });
              return of(
                UserProfileActions.queryConsultantsTenantDetailsError()
              );
            })
          );
        }
      })
    );
  });

  updateNotificationSettingsSubscription$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserProfileActions.updateNotificationSettingsSubscription),
        distinctUntilChanged(),
        map((action) => action.wantToReceive),
        switchMap((wantToReceive: boolean) => {
          if (wantToReceive) {
            return this.notificationsService.subscribeEmailNotifications().pipe(
              map((res: INotificationSettings) => {
                this.snackBarService.open({
                  message: 'You have subscribed email notifications',
                  type: SnackBarTypes.SuccessAlt
                });
                return UserProfileActions.updateNotificationSettingsSubscriptionSuccess();
              })
            );
          } else {
            return this.notificationsService
              .unsubscribeEmailNotifications()
              .pipe(
                map((res: INotificationSettings) => {
                  this.snackBarService.open({
                    message: 'You have unsubscribed email notifications',
                    type: SnackBarTypes.SuccessAlt
                  });
                  return UserProfileActions.updateNotificationSettingsSubscriptionSuccess();
                })
              );
          }
        }),
        catchError((err) => {
          console.error(
            '[UserProfileEffects] There was a problem while saving notifications subscription',
            err
          );
          this.snackBarService.open({
            message: 'Failed to update notification subscription',
            type: SnackBarTypes.ErrorAlt
          });
          return of(
            UserProfileActions.updateNotificationSettingsSubscriptionError()
          );
        }),
        repeat()
      );
    }
  );

  updateTermsEventInvitationSubscription$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserProfileActions.updateTermsEventInvitationSubscription),
        distinctUntilChanged(),
        map((action) => action.accepted),
        switchMap((accepted: boolean) => {
          const successMessage = accepted
            ? 'You are now subscribed to 7N Event Invitations and general information'
            : 'You are now unsubscribed from 7N Event Invitations and general information';
          return this.userProfileService
            .updateTermsEventInvitation({
              AcceptMy7nEventInvitation: accepted
            })
            .pipe(
              map(() => {
                this.snackBarService.open({
                  message: successMessage,
                  type: SnackBarTypes.SuccessAlt
                });

                this.globalAppConfigFacadeService.updateUser({
                  AcceptedMy7nEventInvitation: accepted
                });

                return UserProfileActions.updateTermsEventInvitationSubscriptionSuccess();
              })
            );
        }),
        catchError((err) => {
          console.error(
            '[UserProfileEffects] There was a problem while saving event invitation terms',
            err
          );
          this.snackBarService.open({
            message: 'Failed to update consents',
            type: SnackBarTypes.ErrorAlt
          });
          return of(
            UserProfileActions.updateTermsEventInvitationSubscriptionError()
          );
        }),
        repeat()
      );
    }
  );

  updateTermsNewsletterSubscription$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserProfileActions.updateTermsNewsletterSubscription),
      distinctUntilChanged(),
      map((action) => action.accepted),
      switchMap((accepted: boolean) => {
        const successMessage = accepted
          ? 'You are now subscribed to the 7N Newsletter'
          : 'You are now unsubscribed from the 7N Newsletter';
        return this.userProfileService
          .updateTermsNewsletter({
            AcceptMy7nNewsletter: accepted
          })
          .pipe(
            map(() => {
              this.snackBarService.open({
                message: successMessage,
                type: SnackBarTypes.SuccessAlt
              });

              this.globalAppConfigFacadeService.updateUser({
                AcceptedMy7nEventInvitation: accepted
              });

              return UserProfileActions.updateTermsNewsletterSubscriptionSuccess();
            })
          );
      }),
      catchError((err) => {
        console.error(
          '[UserProfileEffects] There was a problem while saving newsletter terms',
          err
        );
        this.snackBarService.open({
          message: 'Failed to update consents',
          type: SnackBarTypes.ErrorAlt
        });
        return of(UserProfileActions.updateTermsNewsletterSubscriptionError());
      }),
      repeat()
    );
  });

  updateNotificationSettingsFrequency$: Observable<Action> = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserProfileActions.updateNotificationSettingsFrequency),
        distinctUntilChanged(),
        map((action) => action.settings),
        switchMap((settings: INotificationSettings) => {
          return this.notificationsService
            .setEmailNotificationsFrequency(
              settings.SummaryNotificationFrequency
            )
            .pipe(
              map(() => {
                this.snackBarService.open({
                  message: "Your Notifications' preferences have been saved",
                  type: SnackBarTypes.SuccessAlt
                });
                return UserProfileActions.updateNotificationSettingsFrequencySuccess(
                  {
                    settings
                  }
                );
              })
            );
        }),
        catchError((err) => {
          console.error(
            '[UserProfileEffects] There was a problem while saving newsletter terms',
            err
          );
          this.snackBarService.open({
            message:
              "There was a problem while saving your Notifications' preferences, please try again later",
            type: SnackBarTypes.ErrorAlt
          });
          return of(
            UserProfileActions.updateNotificationSettingsFrequencyError()
          );
        }),
        repeat()
      );
    }
  );
}
