import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import VimeoPlayer from '@vimeo/player';

// Services
import { UserProfileService } from '../../services/user-profile.service';
import { ApplicationInsightsService } from '../../services/application-insights.service';
import { ChatBotService } from '@my7n/ui';
import { GlobalAppConfigFacadeService } from '../../services/facades/global-app-config-facade.service';

// Interfaces
import { IUserPreferences } from '../../interfaces/user-preferences';
import { IVideoPlayerConfig, LogVideoEvents, LogVideoModules, videoPlayerDefaultConfig } from '../../interfaces/video-player';
import { take } from 'rxjs';

@Component({
  selector: 'welcome-page',
  templateUrl: './welcome-page.component.html',
  styleUrls: ['./welcome-page.component.scss']
})
export class WelcomePageComponent implements OnInit, OnDestroy {
  @ViewChild('welcomeContentVideo', { static: true }) welcomeContentVideo: ElementRef;
  vimeoPlayer: VimeoPlayer;

  loading = false;
  Agreement = [];
  showVideo = false;
  ratio = 566 / 1008;
  videoPlayerConfig: IVideoPlayerConfig = {
    ...videoPlayerDefaultConfig,
    containerWidth: 1008,
    containerHeight: 566
  }

  watchedVideo = false;
  startedVideo = false;
  user: IUserPreferences;

  LogVideoModules = LogVideoModules;

  /**
   * window.resize event listener function.
   */
  windowResizeListener = () => this.resizeIframe();

  constructor(private router: Router,
    private userProfileService: UserProfileService,
    private globalAppConfigFacadeService: GlobalAppConfigFacadeService,
    private chatBotService: ChatBotService,
    private appInsightsService: ApplicationInsightsService) { }

  ngOnInit() {
    this.globalAppConfigFacadeService.user$
    .pipe(take(1))
    .subscribe(user => {
      this.user = user;
      this.watchedVideo = this.user.WatchedWelcomeVideo;
    });

    this.chatBotService.changeMessage({
      messageHTMLString: 'We look forward to serving you as your agent. We help the best IT specialists to get interesting jobs and build satisfying carriers. Create your CV to help us in that process.'
    });
  }

  ngOnDestroy() {
    window.removeEventListener('resize', this.windowResizeListener);
  }

  // Resize iframe for video to match all responsive widths
  resizeIframe(): void {
    const nativeElement = this.welcomeContentVideo.nativeElement;
    const width: number = nativeElement ? nativeElement.getBoundingClientRect().width : this.videoPlayerConfig.containerWidth;

    window.setTimeout(() => {
      this.videoPlayerConfig.containerWidth = width;
      this.videoPlayerConfig.containerHeight = Math.round(width * this.ratio);
    }, 10);
  }

  show(): void {
    this.resizeIframe();
    this.showVideo = true;
    window.addEventListener('resize', this.windowResizeListener);
  }

  iframeLoaded() {
    const iframe = document.getElementById('welcomeVideo');
    this.vimeoPlayer = new VimeoPlayer(iframe);

    this.vimeoPlayer.on('play', () => {
      if (!this.startedVideo) {
        this.startedVideo = true;
        this.appInsightsService.trackEvent('welcome-video-started', {
          'Message': 'User has started watching intro video on welcome page',
          'UserId': this.user.Id.toString()
        });
      }
    });

    this.vimeoPlayer.on('ended', () => {
      this.watchedVideo = true;
      this.startedVideo = false;
      this.showVideo = false;
      this.appInsightsService.trackEvent('welcome-video-ended', {
        'Message': 'User has watched intro video on welcome page',
        'UserId': this.user.Id.toString()
      });
    });
  }

  onVideoPlayerEvent(event: LogVideoEvents) {
    if(event === LogVideoEvents.Played) {
      this.onVideoPlayed();
    } else if(event === LogVideoEvents.Ended) {
      this.onVideoEnded();
    }
  }

  private onVideoPlayed() {
    if (!this.startedVideo) {
      this.startedVideo = true;
      this.appInsightsService.trackEvent('welcome-video-started', {
        'Message': 'User has started watching intro video on welcome page',
        'UserId': this.user.Id.toString()
      });
    }
  }

  private onVideoEnded() {
    this.watchedVideo = true;
    this.startedVideo = false;
    this.showVideo = false;
    this.appInsightsService.trackEvent('welcome-video-ended', {
      'Message': 'User has watched intro video on welcome page',
      'UserId': this.user.Id.toString()
    });
  }

  letMeIn() {
    this.loading = true;

    this.userProfileService.updateWelcomeScreenVisit(true).then(() => {
      this.router.navigateByUrl('cv/wizard').then(() => console.debug('[WelcomePageComponent] Redirected to CV Wizard'));
      this.loading = false;
    });
  }

}
