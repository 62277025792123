import {
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
  OnDestroy
} from '@angular/core';
import { INewsView, NEWS_DETAIL_URLS } from '../../../../../interfaces/news';
import { NewsAttachmentTypes } from '../../../../../interfaces/news-attachment';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { INewsMediaSize } from '../../../../../interfaces/news-media-size';
import { IMediaQueriesBreakpoints, LayoutContainerModes, MediaQueryService, SubNavbarService } from '@my7n/ui';
import { NewsViewSubmenuComponent } from './news-view-submenu/news-view-submenu.component';
import { ActivatedRoute, Router } from '@angular/router';
import { PageTitleService } from '../../../../../services/page-title.service';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TimelineNewsFacadeService } from '../../../../../services/facades/timeline-news-facade.service';
import { IVideoPlayerConfig, LogVideoModules, videoPlayerDefaultConfig } from '../../../../../interfaces/video-player';
import { ContentTypes } from '../../../../../interfaces/reactions';

@Component({
  selector: 'news-view',
  templateUrl: './news-view.component.html',
  styleUrls: ['./news-view.component.scss']
})
export class NewsViewComponent implements OnInit, OnDestroy {
  LayoutContainerModes = LayoutContainerModes;
  @Input() news: INewsView;
  @ViewChild('newsDescription', { static: true }) newsDescription: ElementRef;
  @ViewChild('subNavbarContainer', { static: true }) subNavbar: ElementRef;

  imageSize: INewsMediaSize;

  showVideo: boolean;

  newsSubtype: number;

  templateTimeout = null;

  trustedResourceUrl: SafeUrl;

  galleryItemsInRowNumber: number;

  LogVideoModules = LogVideoModules;

  videoPlayerConfig: IVideoPlayerConfig = {
    ...videoPlayerDefaultConfig
  };

  ratio = 421 / 750;

  readonly newsSubtypes = {
    TEXT_ONLY: 1,
    IMG_ONLY: 2,
    VIDEO_ONLY: 3,
    GALLERY: 4
  };

  readonly galleryItemsInRow = {
    MOBILE: 3,
    DESKTOP: 5
  };

  private _destroySubject$ = new Subject<void>();

  get ATTACHMENT_TYPES() {
    return NewsAttachmentTypes;
  }

  /**
   * window.resize listener function.
   */
  windowResizeListener = () => this.resizeContent();

  contentType = ContentTypes.MY7N_NEWS;

  constructor(
    private sanitizer: DomSanitizer,
    private subNavbarService: SubNavbarService,
    private route: ActivatedRoute,
    private titleService: PageTitleService,
    private mediaQueryService: MediaQueryService,
    private router: Router,
    private timelineNewsFacade: TimelineNewsFacadeService
  ) {}

  ngOnInit() {
    this.galleryItemsInRowNumber = this.galleryItemsInRow.DESKTOP;
    // detecting news subtype
    this.route.params
      .pipe(
        takeUntil(this._destroySubject$),
        filter(params => 'newsId' in params)
      )
      .subscribe(() => {
        this.news = this.route.snapshot.data.news;
        this.titleService.setTitle(this.news.Title);
      });

    this.newsSubtype = this.getNewsSubType();

    this.resizeContent();

    if (this.newsSubtype === this.newsSubtypes.IMG_ONLY) {
      this.imageSize = {
        width: 750,
        height: 376,
        ratio: 376 / 750
      };
    } else if (this.newsSubtype === this.newsSubtypes.VIDEO_ONLY) {
      this.videoPlayerConfig = {
        ...this.videoPlayerConfig,
        containerWidth: 750,
        containerHeight: 421
      };
      this.showVideo = false;

      switch (this.news.Attachments[0].Type) {
        case this.ATTACHMENT_TYPES.OTHER:
          this.trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.news.Attachments[0].Url
          );
          break;
        case this.ATTACHMENT_TYPES.YT:
        case this.ATTACHMENT_TYPES.VIMEO:
          this.trustedResourceUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
            this.news.Attachments[0].Url.replace('autoplay=0', 'autoplay=1')
          );
          break;
        default:
          console.error(
            '[NewsViewComponent] Unrecognized video type: ' +
              this.news.Attachments[0].Type
          );
      }
    }
    this.subNavbarService.updateSecondLevelComponent(NewsViewSubmenuComponent);

    if (this.newsSubtype === this.newsSubtypes.GALLERY) {
      this.mediaQueryService.breakpoints$
        .pipe(takeUntil(this._destroySubject$))
        .subscribe((breakpoints: IMediaQueriesBreakpoints) => {
          const isXsMobile = breakpoints.ltSm;

          if (isXsMobile) {
            this.galleryItemsInRowNumber = this.galleryItemsInRow.MOBILE;
          } else {
            this.galleryItemsInRowNumber = this.galleryItemsInRow.DESKTOP;
          }
        });
    }
  }

  /**
   * Returns news subtype.
   * @returns {number}
   */
  getNewsSubType(): number {
    if (this.news.Attachments.length === 0) {
      return this.newsSubtypes.TEXT_ONLY;
    } else if (
      this.news.Attachments.length === 1 &&
      this.news.Attachments[0].Type === NewsAttachmentTypes.IMG
    ) {
      return this.newsSubtypes.IMG_ONLY;
    } else if (
      this.news.Attachments.length === 1 &&
      this.news.Attachments[0].Type !== NewsAttachmentTypes.IMG
    ) {
      return this.newsSubtypes.VIDEO_ONLY;
    } else {
      return this.newsSubtypes.GALLERY;
    }
  }

  /**
   * Resize video iframe to match all responsive widths.
   */
  resizeContent() {
    window.setTimeout(() => {
      const width = this.newsDescription.nativeElement.offsetWidth;
      if (this.newsSubtype === this.newsSubtypes.IMG_ONLY) {
        this.imageSize.width = width;
        this.imageSize.height = Math.round(width * this.imageSize.ratio);
      }

      if (this.newsSubtype === this.newsSubtypes.VIDEO_ONLY) {
        this.videoPlayerConfig = {
          ...this.videoPlayerConfig,
          containerWidth: width,
          containerHeight: Math.round(width * this.ratio)
        }
      }
    });
  }

  /**
   * Shows video.
   */
  show() {
    this.showVideo = true;
  }

  getBanner(): string {
    if (this.newsSubtype === this.newsSubtypes.IMG_ONLY) {
      return this.news.Attachments[0].Url;
    }

    return null;
  }

  ngOnDestroy() {
    const gointToNewsView = NEWS_DETAIL_URLS.some((url) => this.router.url.startsWith(url));
    const gointToTimeline = this.router.url === '' || this.router.url === '/';

    if (!(gointToNewsView || gointToTimeline)) {
      this.timelineNewsFacade.clearNews();
    }

    window.removeEventListener('resize', this.windowResizeListener);
    if (this.templateTimeout) {
      window.clearTimeout(this.templateTimeout);
    }
    this._destroySubject$.next();
  }
}
