/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable, OnDestroy } from '@angular/core';
import { lastValueFrom, noop, Observable, of, Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';
// INTERFACES
import { IPhones } from '../interfaces/phones';
import { IAddress } from '../interfaces/address';
import { IPrintPreferences } from '../interfaces/print-preferences';
import { IConsultantSearched, IConsultantSearchedSelectable } from '../interfaces/consultant-searched';
import { IConsultantDetails } from '../interfaces/consultant-details';
import { IConsultantSearchResponse } from '../interfaces/consultant-search-result';
import { IConsultantCreateCvResponse } from '../interfaces/consultant-create-cv-response';
import { ServiceNames } from '../interfaces/my7n-env-config';
import { IQuerySearchParams } from '../interfaces/search';
import { B2CUserStatus, IB2CUsersResponse, IB2CUserStatus } from '../interfaces/b2c';
import { ISearchFavouriteFilter, ISearchFavouriteFiltersResponse } from '../interfaces/serach-filters-data';
import { GlobalAppConfigFacadeService } from './facades/global-app-config-facade.service';
import { AppConfigService } from './app-config.service';


@Injectable()
export class AgentService implements OnDestroy {

  readonly API_AGENT: string;
  readonly API_CONSULTANTS: string;
  readonly API_BOOKMARKS: string;
  readonly API_CVS: string;
  readonly API_BLOB: string;
  readonly API_SEARCH: string;
  readonly API_USER: string;
  readonly API_KEY_VALUE_STORAGE: string;
  readonly CV_V1_API: string;

  private unsubscribe$ = new Subject<void>();
  printSettings: IPrintPreferences;

  constructor(private http: HttpClient,
              private appConfigService: AppConfigService,
              private globalAppConfigFacadeService: GlobalAppConfigFacadeService) {

    const apiPrefix = this.appConfigService.serviceUrl(ServiceNames.Core);

    // @TODO cleanup this code after B2C is completely implemented

    this.API_AGENT = apiPrefix + 'agent/';
    this.API_CONSULTANTS = apiPrefix + 'consultants/';
    this.API_BOOKMARKS = apiPrefix + 'bookmarks/';
    this.API_CVS = apiPrefix + 'cvs/';
    this.API_BLOB = apiPrefix + 'blobs/';
    this.API_SEARCH = apiPrefix + 'search/';
    this.API_USER = this.appConfigService.serviceUrl(ServiceNames.User, 'v1') + 'user/';
    this.API_KEY_VALUE_STORAGE = this.appConfigService.serviceUrl(ServiceNames.KeyValueStorage, 'v1') + 'key-value-storage/';
    this.CV_V1_API = this.appConfigService.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/';

    this.globalAppConfigFacadeService.printPreferences$
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (settings: IPrintPreferences) => {
          this.printSettings = { ...settings };
        }
      );
  }

  private prepareQueryString(preferences) {
    let preferencesKeys;

    if (typeof (preferences) !== 'object') {
      preferences = {};
    }

    preferencesKeys = Object.keys(preferences);

    if (preferencesKeys.length > 0) {
      preferencesKeys = preferencesKeys.map(function (key) {
        return key + '=' + preferences[key];
      });

      return preferencesKeys.join('&');
    }

    return '';
  }

  private prepareQueryStringFromArray(key, array) {
    let i,
      queryString = '';

    for (i = 0; i < array.length; i++) {
      queryString += String(key + array[i]);
    }

    return queryString;
  }

  private prepareQueryStringFromSkillsArray(array) {
    let i,
      queryString = '';

    for (i = 0; i < array.length; i++) {
      const skillArray = array[i].split(',');
      queryString += '&Skills[' + i + '].Id=' + skillArray[0] + '&Skills[' + i + '].Lvl=' + skillArray[6] + '&Skills[' + i + '].YearsExp=' + skillArray[5] + '&Skills[' + i + '].LastYear=' + skillArray[4];
    }

    return queryString;
  }

  private prepareQueryStringFromLanguagesArray(array) {
    let i,
      queryString = '';

    for (i = 0; i < array.length; i++) {
      const languagesArray = array[i].split(',');
      queryString += '&Languages[' + i + '].Id=' + languagesArray[0] + '&Languages[' + i + '].Lvl=' + languagesArray[3];
    }

    return queryString;
  }

  private prepareQueryStringFromTwoTiersArray(array) {
    let i,
      j,
      queryString = '';

    for (i = 0; i < array.length; i++) {
      const sectionIdsArray = array[i].split(',');
      const parentId = sectionIdsArray.shift();
      queryString += '&Relation[' + i + '].Id=' + parentId;

      for (j = 0; j < sectionIdsArray.length; j++) {
        queryString += '&Relation[' + i + '].Sub[' + j + ']=' + sectionIdsArray[j];
      }
    }

    return queryString;
  }

  getConsultant(consultantId: string): Promise<any> {
    return lastValueFrom(this.http.get(this.API_CONSULTANTS + consultantId))
      .then((result: any) => {
        return result;
      }).catch((error) => {
        console.error('[AgentService] Failed to get consultant ' + consultantId + ' data', error);
        return Promise.reject(error);
      });
  }

  block(consultantId: string): Observable<any> {
    return this.http.patch(`${this.API_USER}consultant/${consultantId}/disable`, null).pipe(catchError((error: any) => {
      console.error('[AgentService] Failed to block consultant ' + consultantId, error);
      return throwError(() => new Error(error));
    }));
  }

  unblock(consultantId: string): Observable<any> {
    return this.http.patch(`${this.API_USER}consultant/${consultantId}/enable`, null).pipe(catchError((error: any) => {
      console.error('[AgentService] Failed to block consultant ' + consultantId, error);
      return throwError(() => new Error(error));
    }));
  }

  bookmark(bookmarkedConsultants: Array<IConsultantSearchedSelectable | IConsultantDetails>): Observable<any> {
    const ids: string[] = bookmarkedConsultants.map(consultant => consultant.Id);

    return this.http.put(this.API_BOOKMARKS + 'consultants', {
      Bookmarks: ids
    }).pipe(catchError((error: any) => {
      console.error('[AgentService] Failed to bookmark consultants ' + ids, error);
      return throwError(() => error);
    }));
  }

  unbookmark(consultantId: string): Observable<any> {
    return this.http.delete(this.API_BOOKMARKS + 'consultants/' + consultantId).pipe(catchError((error: any) => {
      console.error('[AgentService] Failed to unbookmark consultant with id: ' + consultantId, error);
      return throwError(() => error);
    }));
  }

  /**
   * Get bookmarked consultants
   * @type {Observable}
   * @param {any} params Url params
   * @param {number} pageIndex Page index of page we want to load results. Default 0
   * @param {number} pageSize Page size of pages
   * @param {number} offsetAdjustment Additional offset for quering pages. (Eg. Someone unbookmarks one record on page 1 so when calling results for page 2 we need to shift offset by - 1 to get proper list)
   */

  getBookmarked(params: any, pageIndex = 0, pageSize: number, offsetAdjustment = 0) {
    const orderBy = params.orderBy,
          offset = (pageSize * pageIndex) - offsetAdjustment;

    return this.http.get<any>(this.API_BOOKMARKS + 'consultants?' +
      '&Offset=' + offset +
      '&PageSize=' + pageSize +
      (orderBy ? '&OrderBy=' + orderBy : '')
    ).pipe(
      map((result: IConsultantSearchResponse<IConsultantSearched>) => {
        const transformedConsultantList = result.Consultants.map((consultant: IConsultantSearched) => {
          return { ...consultant, selected: false };
        });

        return { ...result, Consultants: transformedConsultantList };
      }),
      catchError((e) => {
        if (e.status === 404 || e.status === 400) {
          console.error('[AgentService] Empty response or bad request', e);

          return of({
            Total: 0,
            Consultants: []
          });
        }

        console.error('[AgentService] Error occurred during consultants loading', e);
        return throwError(() => e);
      })
    );
  }

  getBookmarkedCount(): Observable<number> {
    return this.http.get<number>(`${this.API_BOOKMARKS}consultants/number`)
    .pipe(catchError((error: any) => {
      console.error('[AgentService] Failed to get bookmarked consultants count', error);
      return throwError(() => error);
    }));
  }

  checkPrimaryCv(cvId: number): Promise<any> {
    return lastValueFrom(this.http.patch(this.CV_V1_API + cvId + '/primary', null))
      .then(noop).catch((error) => {
        console.error('[AgentService] Failed to check primary cv of consultant', error);
        return Promise.reject(error);
      });
  }

  deleteConsultantCv(consultantId: string, cvId: number): Promise<any> {
    return lastValueFrom(this.http.delete(this.API_CONSULTANTS + consultantId + '/cv/' + cvId))
      .then((result: any) => {
        return result;
      }).catch((error) => {
        console.error('[AgentService] Failed to delete cv', error);
        return Promise.reject(error);
      });
  }

  sendToEmail(cvId: number, preferences?: IPrintPreferences): Promise<any> {
    if (typeof (cvId) !== 'number') {
      console.error('[AgentService] Given CV Id: ' + cvId + ' is not a proper number');
    }

    preferences = preferences || this.printSettings;

    return lastValueFrom(this.http.put(this.API_CVS + cvId + '/send?' + this.prepareQueryString(preferences), null))
      .then((result: any) => {
        return result;
      })
      .catch((error) => {
        console.error('[AgentService] Cannot send selected CV: ' + cvId + ' to email', error);
        return Promise.reject(error);
      });
  }

  sendManyToEmail(cvIdList: number[]): Promise<any> {
    if (!Array.isArray(cvIdList)) {
      console.error('[AgentService] Given CV Id array is not a proper array');
    }

    return lastValueFrom(this.http.put(this.API_CVS + 'send?' + this.prepareQueryString(this.printSettings), cvIdList))
      .then((result: any) => {
        return result;
      }).catch((error) => {
        console.error('[AgentService] Cannot send selected CVs: ' + cvIdList + ' to email', error);
        return Promise.reject(error);
      });
  }

  sendWelcomeEmail(consultantId: string, emailContent: string): Promise<any> {
    if (!consultantId) {
      console.error('[AgentService] Consultant Id is invalid, cannot send welcome email');
    }

    // @TODO cleanup after B2C is completely implemented
    // B2C welcome email url https://api-staging.7n.eu/api/v1/user/consultant/{id}/invite

    return lastValueFrom(this.http.post(this.API_USER + 'consultant/' + consultantId + '/invite', {
      message: emailContent
    })).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to send welcome email', error);
      return Promise.reject(error);
    });
  }

  sendFollowUpEmail(consultantId: string, emailContent: string): Promise<any> {
    if (!consultantId) {
      console.error('[AgentService] Consultant Id is invalid, cannot send follow up email');
    }

    return lastValueFrom(this.http.put(this.API_CONSULTANTS + consultantId + '/followUp', {
      message: emailContent
    })).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to send follow up email', error);
      return Promise.reject(error);
    });
  }

  create(guid: string) {
    return lastValueFrom(this.http.put<any>(this.API_CONSULTANTS + 'account/create', {
      Guid: guid
    })).catch((e) => {
        const errorMessage = e && e.error && e.error.Message;
        console.error(`[AgentService] Cannot create new consultant ${e.status} ${errorMessage}`);
        return Promise.reject(e);
      });
  }

  createCvForConsultant(consultantId: string): Observable<IConsultantCreateCvResponse> {
    return this.http.post<IConsultantCreateCvResponse>(this.CV_V1_API + consultantId + '/create', { Name: 'My new CV' })
    .pipe(catchError((error: any) => {
      console.error('[AgentService] Failed to create cv for consultant', error);
      return throwError(() => error);
    }));
  }

  updateEmail(emailValue: string, consultantId: string): Promise<any> {
    return lastValueFrom(this.http.patch(this.API_CONSULTANTS + consultantId + '/contact/email', { Email: emailValue })).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to update consultant email', error);
      return Promise.reject(error);
    });
  }

  updatePhones(phones: IPhones, consultantId: string): Promise<any> {
    return lastValueFrom(this.http.patch(this.API_CONSULTANTS + consultantId + '/contact/phones', phones)).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to update consultant phones', error);
      return Promise.reject(error);
    });
  }

  updateSkypeId(skype: string, consultantId: string): Promise<any> {
    return lastValueFrom(this.http.patch(this.API_CONSULTANTS + consultantId + '/contact/skypeid', { SkypeId: skype })).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to update consultant skypeId', error);
      return Promise.reject(error);
    });
  }

  updateCompanyName(name: string, consultantId: string): Promise<any> {
    return lastValueFrom(this.http.patch(this.API_CONSULTANTS + consultantId + '/contact/companyName', { CompanyName: name })).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to update consultant company name', error);
      return Promise.reject(error);
    });
  }

  updateCompanyWebsite(website: string, consultantId: string): Promise<any> {
    return lastValueFrom(this.http.patch(this.API_CONSULTANTS + consultantId + '/contact/companyWebsite', { CompanyWebsite: website })).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to update consultant company website', error);
      return Promise.reject(error);
    });
  }

  updateAddress(address: IAddress, consultantId: string): Promise<any> {
    return lastValueFrom(this.http.patch(this.API_USER + 'personal-details/' + consultantId + '/address', address)).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to update consultant address', error);
      return Promise.reject(error);
    });
  }

  updateLocations(locations: number[], consultantId: string): Promise<any> {
    return lastValueFrom(this.http.patch(this.API_CONSULTANTS + consultantId + '/contact/locations', { Locations: locations })).then((result: any) => {
      return result;
    }).catch((error) => {
      console.error('[AgentService] Failed to update consultant location', error);
      return Promise.reject(error);
    });
  }

  crmFileSendToMail(fileId: string): Promise<any> {
    return lastValueFrom(this.http.put(this.API_BLOB + 'crmfiles/' + fileId + '/sendtome', null)).then((result: any) => {
        return result;
      }).catch((error) => {
        console.error('[AgentService] Failed to send file to email', error);
        return Promise.reject(error);
      });
  }

  getConsultantSearchResults(params: IQuerySearchParams, pageIndex = 0, pageSize: number): Observable<IConsultantSearchResponse<IConsultantSearchedSelectable>> {
    const searchKeywords = params.keywords,
      relationshipTwoTiersArray = params.checkedRelationshipTypesIds,
      consultantStatusArray = params.checkedConsultantStatusIds,
      consultantActiveStatusArray = params.checkedConsultantActiveStatusIds,
      availability = params.availability,
      availabilityRangeFrom = params.availabilityRangeFrom,
      availabilityRangeTo = params.availabilityRangeTo,
      orderBy = params.orderBy,
      industryKnowledgesArray = params.checkedIndustryFocusIds,
      workRolesArray = params.checkedWorkRolesIds,
      cvLanguagesArray = params.checkedCvLanguagesIds,
      competenceAreasArray = params.checkedCompetenceAreasIds,
      privateAddressCountryArray = params.checkedPrivateAddressCountryIds,
      userLocationsArray = params.checkedLocationsIds,
      ownersArray = params.owners,
      skillsArray = params.skills,
      skillsRestricted = params.skillsRestricted,
      languagesArray = params.languages,
      languagesRestricted = params.languagesRestricted,
      offset = pageSize * pageIndex;

    return this.http.get<IConsultantSearchResponse<IConsultantSearched>>(this.API_SEARCH + 'consultants?' +
      'Offset=' + offset +
      '&PageSize=' + pageSize +
      (relationshipTwoTiersArray ? this.prepareQueryStringFromTwoTiersArray(relationshipTwoTiersArray) : '') +
      (consultantStatusArray ? this.prepareQueryStringFromArray('&ConsultantStatus=', consultantStatusArray) : '') +
      (consultantActiveStatusArray ? this.prepareQueryStringFromArray('&ConsultantActiveStatus=', consultantActiveStatusArray) : '') +
      (searchKeywords ? '&Keywords=' + encodeURIComponent(searchKeywords) : '') +
      (availability ? '&Availability=' + availability : '') +
      (availabilityRangeFrom ? '&AvailabilityRange.From=' + availabilityRangeFrom : '') +
      (availabilityRangeTo ? '&AvailabilityRange.To=' + availabilityRangeTo : '') +
      (orderBy ? '&OrderBy=' + orderBy : '') +
      (industryKnowledgesArray ? this.prepareQueryStringFromArray('&IndustryKnowledges=', industryKnowledgesArray) : '') +
      (workRolesArray ? this.prepareQueryStringFromArray('&WorkRoles=', workRolesArray) : '') +
      (cvLanguagesArray ? this.prepareQueryStringFromArray('&CvLanguages=', cvLanguagesArray) : '') +
      (competenceAreasArray ? this.prepareQueryStringFromArray('&CompetenceAreas=', competenceAreasArray) : '') +
      (privateAddressCountryArray ? this.prepareQueryStringFromArray('&Countries=', privateAddressCountryArray) : '') +
      (userLocationsArray ? this.prepareQueryStringFromArray('&Locations=', userLocationsArray) : '') +
      (ownersArray ? this.prepareQueryStringFromArray('&Owners=', ownersArray) : '') +
      (skillsArray ? this.prepareQueryStringFromSkillsArray(skillsArray) : '') +
      (languagesArray ? this.prepareQueryStringFromLanguagesArray(languagesArray) : '') +
      '&SkillsRestricted=' + skillsRestricted +
      '&LanguagesRestricted=' + languagesRestricted
    ).pipe(
      map((result: IConsultantSearchResponse<IConsultantSearched>) => {
        const transformedConsultantList = result.Consultants.map((consultant: IConsultantSearched) => {
          return { ...consultant, selected: false };
        });

        return { ...result, Consultants: transformedConsultantList };
      }),
      catchError((e) => {
        if (e.status === 404 || e.status === 400) {
          console.error('[AgentService] Empty response or bad request', e);

          return of({
            Total: 0,
            Consultants: []
          });
        }

        console.error('[AgentService] Error occurred during consultants loading', e);
        return throwError(() => e);
      })
    );
  }

  /**
   * Returns information about B2C users for specified email.
   * @param email
   */
  getB2CUsers(email: string): Observable<IB2CUsersResponse> {
    return this.http.get<IB2CUsersResponse>(`${this.API_USER}account/b2c?email=${email}`)
      .pipe(catchError((error: any) => {
        console.error('[AgentService] Failed to get user duplication info', error);
        return throwError(() => error);
      }));
  }

  /**
   * Checks if user with specified email and crmContactId exists in B2C
   * @param email
   * @param crmContactId
   */
  getUserB2CStatus(email: string, crmContactId?: string): Observable<IB2CUserStatus> {
    return this.getB2CUsers(email).pipe(
      switchMap((b2cInfo) => {
        const userB2CStatus: IB2CUserStatus = {
          status: B2CUserStatus.NotExists,
          duplicates: [],
          crmContactId
        };

        if (b2cInfo.Users.length) {
          for (let i = 0; i < b2cInfo.Users.length; i++) {
            if (b2cInfo.Users[i].UserId === crmContactId) {
              // checked user is already available in B2C
              userB2CStatus.status = B2CUserStatus.Exists;
              userB2CStatus.isActive = b2cInfo.Users[i].IsActive;
            } else {
              // Other user in B2C was registered with this email
              userB2CStatus.duplicates.push(b2cInfo.Users[i]);
            }
          }

          if (userB2CStatus.duplicates.length) {
            userB2CStatus.status = B2CUserStatus.EmailUsedByOtherAccount;
          }
        }

        return of(userB2CStatus);
      })
    );
  }

  getFavouriteFilters(): Observable<ISearchFavouriteFiltersResponse> {
    return this.http.get<ISearchFavouriteFiltersResponse>(`${this.API_KEY_VALUE_STORAGE}filters`)
    .pipe(
      catchError((error) => {
      console.error('[AgentService] Failed to get favourite filters', error);
      return throwError(() => error);
    }));
  }

  saveFavouriteFilters(filters: Array<ISearchFavouriteFilter>) {
    return this.http.put<Array<ISearchFavouriteFilter>>(`${this.API_KEY_VALUE_STORAGE}filters`, filters)
    .pipe(
      catchError((error) => {
      console.error('[AgentService] Failed to save favourite filters', error);
      return throwError(() => error);
    }));
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
  }
}
