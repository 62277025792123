<section
  class="personal-details"
  data-test-id="user-profile-personal-details-container"
  *rxLet="state$ as state">
  <h4 class="font-bold">Personal details</h4>
  <ng-container *ngIf="!state.personalDetailsLoading; else loading">
    <ng-container *ngIf="state.personalDetails; else nodata">
      <labeled-edit
        label="Email address"
        type="email"
        data-test-id="user-profile-emailAddressEdit-container"
        [maxlength]="MAX_LENGTHS.email"
        placeholder="john.doe@7N.com"
        [fieldValue]="state.personalDetails?.Email"
        [required]="false"
        class="email-address"
        updateMethodName="updateEmailProfile"
        [texts]="{ emailNotValid: 'Enter email address' }"
        (serverError)="onServerError()"
        (updateSuccess)="personalDetailsEmailUpdated($event)"
        [canEdit]="state.canEdit">
      </labeled-edit>
      <phones-edit
        [phones]="state.personalDetails?.Phones"
        data-test-id="user-profile-phonesEdit-container"
        updateMethodName="updatePhonesProfile"
        (serverError)="onServerError()"
        (updateSuccess)="personalDetailsPhonesUpdated($event)"
        [canEdit]="state.canEdit">
      </phones-edit>
      <address-edit
        [address]="state.personalDetails?.Address"
        updateMethodName="updateAddressProfile"
        (serverError)="onServerError()"
        (updateSuccess)="personalDetailsAddressUpdated($event)"
        data-test-id="user-profile-addressEdit-container"
        [canEdit]="state.canEdit">
      </address-edit>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="!state.consultantsTenantDetailsLoading; else loading">
    <div class="alias-box" data-test-id="user-profile-personal-details-alias-box">
      <div class="alias-label">7N Alias</div>
      <div *ngIf="state.consultantsTenantDetails?.ConsultantUPN" class="alias-value">{{ state.consultantsTenantDetails.ConsultantUPN }}</div>
      <div *ngIf="!state.consultantsTenantDetails?.ConsultantUPN" class="alias-value">
        Not registered
        <tooltip-popover #tooltip2 position="bottom" data-test-id="not-registered-tooltipPopover">
          <mat-icon-with-hover #tooltipIcon iconName="tooltip_default" iconNameHover="tooltip_hover"
                               iconNameActive="tooltip_active"></mat-icon-with-hover>
          <ng-template cdkPortal #tooltipPopoverContent="cdkPortal" let-tooltipPopover>
            <div class="tooltip-content">
              If you are 7N Consultant, please contact support&#64;7n.com
            </div>
          </ng-template>
        </tooltip-popover>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #nodata>
  <no-data></no-data>
</ng-template>

<ng-template #loading>
  <div class="flex flex-center-start w-full h-full">
    <loader color="primary"></loader>
  </div>
</ng-template>
