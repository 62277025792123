import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ICheckboxCategoryTreeItem } from '../../../interfaces/checkbox-category-tree-item';

@Component({
  selector: 'checkbox-category-list-item',
  templateUrl: './checkbox-category-list-item.component.html',
  styleUrls: ['./checkbox-category-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxCategoryListItemComponent {
  @HostBinding('class.main-item')
  @Input() isMainItem = false;

  @Input()
  set listitem(listitem: ICheckboxCategoryTreeItem) {
    this._listitem = listitem;

    if (Array.isArray(listitem.Subcategories)) {
      this.checkedSubcategories = listitem.Subcategories.filter(CheckboxCategoryListItemComponent.isCheckedItem);
    } else {
      this.checkedSubcategories = [];
    }
  }

  get listitem() {
    return this._listitem;
  }

  get subcategoriesString(): string {
    return this.listitem.Subcategories
      .filter(CheckboxCategoryListItemComponent.isCheckedItem)
      .map(CheckboxCategoryListItemComponent.mapToItemName)
      .join(', ');
  }

  private _listitem: ICheckboxCategoryTreeItem;
  checkedSubcategories: Array<ICheckboxCategoryTreeItem>;

  private static isCheckedItem(item: ICheckboxCategoryTreeItem) {
    return item.Checked === true;
  }

  private static mapToItemName(item: ICheckboxCategoryTreeItem) {
    return item.Name;
  }
}
