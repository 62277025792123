import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  Input,
  SimpleChanges
} from '@angular/core';
import {
  ContentReactionTypes,
  IContentReactionsItem
} from '../../../../interfaces/reactions';
import { RxState } from '@rx-angular/state';
import { CloseScrollStrategy, ConnectedPosition } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { My7nMaterialModule } from '../../../../modules/my7n-material.module';


@Component({
  standalone: true,
  imports: [CommonModule, My7nMaterialModule],
  selector: 'reactions-list',
  templateUrl: './reactions-list.component.html',
  styleUrls: ['./reactions-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RxState]
})
export class ReactionsListComponent {
  @Input() reactions: IContentReactionsItem[];
  @Input() liked: ContentReactionTypes;

  isPanelVisible = false;

  CloseScrollStrategy = CloseScrollStrategy;
  cdkOverlayPositions: Array<ConnectedPosition> = [
    {
      originX: 'center',
      originY: 'top',
      overlayX: 'center',
      overlayY: 'bottom'
    }
  ];

  @HostListener('mouseleave')
  onMouseLeave() {
    this.isPanelVisible = false;
  }

  @HostListener('click')
  onClick() {
    this.isPanelVisible = !this.isPanelVisible;
  }

  reactionsCounter: Array<{ type: ContentReactionTypes; count: number }> = [];

  constructor() {
    this.liked = ContentReactionTypes.LIKE;
  }

  ngOnInit(): void {
    this.reactionsCounter = this.getReactionsCounter();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.reactionsCounter = this.getReactionsCounter();
  }

  getReactionsCounter(): Array<{
    type: ContentReactionTypes;
    count: number;
  }> {
    const reactionsCounter: Array<{
      type: ContentReactionTypes;
      count: number;
    }> = [];
    if (this.reactions && this.reactions.length > 0) {
      this.reactions.forEach((reaction) => {
        const reactionType = reaction.ReactionType;
        const reactionIndex = reactionsCounter.findIndex(
          (item) => item.type?.toLowerCase() === reactionType?.toLowerCase()
        );
        if (reactionIndex > -1) {
          reactionsCounter[reactionIndex].count++;
        } else {
          reactionsCounter.push({
            type: reactionType.toLowerCase() as ContentReactionTypes,
            count: 1
          });
        }
      });

      reactionsCounter.sort((a, b) => {
        return b.count - a.count;
      });
    }
    return reactionsCounter;
  }

  getTopThreeReactions(limit = 3): Array<ContentReactionTypes> {
    return this.reactionsCounter
      .slice(0, limit)
      ?.map((reaction) => reaction.type);
  }

  isActive(reactionType: ContentReactionTypes): boolean {
    return this.liked?.toLowerCase() === reactionType?.toLowerCase();
  }
}
