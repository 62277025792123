import { Injectable } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UrlTree } from '@angular/router';
import {
  ConfirmDialogComponent,
  ConfirmDialogComponentDefaultConfig,
  IConfirmDialogData,
  MatDialogService,
  ThemeColors
} from '@my7n/ui';
import { iif, Observable, of } from 'rxjs';
import { filter, mergeMap, switchMap, tap } from 'rxjs/operators';
import { GlobalCommonFacadeService } from '../services/facades/global-common-facade.service';

export const LeavingPageDialogConfigData: Readonly<IConfirmDialogData> = {
  dialogHTML:
    'If you are in the process of filling in the form, changes will not be saved.',
  buttonsColor: ThemeColors.PRIMARY,
  confirmButtonText: 'Leave',
  headerText: 'Do you want to leave this page?'
};

@Injectable({
  providedIn: 'root'
})
export class LeavingPageGuard  {
  constructor(
    private matDialogService: MatDialogService,
    private globalCommonFacadeService: GlobalCommonFacadeService
  ) {}

  canDeactivate(
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const afterClosed$ = this.globalCommonFacadeService.leavingPageGuardEnabled$.pipe(
      filter((value) => value === true),
      switchMap(() => {
        return this.openDialog()
          .afterClosed()
          .pipe(
            tap((value) => {
              if (value) {
                // The Leave button pressed
                // reseting the global flag
                this.globalCommonFacadeService.updateLeavingPageGuardEnabled(false);
              }
            })
          );
      })
    );

    // if leavingPageGuardEnabled === true then show the dialog and return the result of afterClosed$ observable
    // if leavingPageGuardEnabled === false then return true to allow navigation without the dialog
    return this.globalCommonFacadeService.leavingPageGuardEnabled$.pipe(
      mergeMap((value) => iif(() => value === true, afterClosed$, of(true)))
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private openDialog(): MatDialogRef<ConfirmDialogComponent, any> {
    return this.matDialogService.open(ConfirmDialogComponent, {
      ...ConfirmDialogComponentDefaultConfig,
      data: LeavingPageDialogConfigData
    });
  }
}
