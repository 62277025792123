import {
  ActionReducerMap,
  MetaReducer,
  createFeatureSelector,
  createSelector
} from '@ngrx/store';
import { routerReducer, RouterReducerState } from '@ngrx/router-store';

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface State {}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer
};

export const metaReducers: MetaReducer<State>[] = [];

// app level selectors
// ngrx router state selectors
export const selectRoueter = createFeatureSelector<RouterReducerState>(
  'router'
);

export const getRouterState = createSelector(
  selectRoueter,
  state => state.state
);

export const selectRouterUrl = createSelector(
  getRouterState,
  state => state.url
);

export const selectRouterParams = createSelector(
  getRouterState,
  state => state.root.params
);

export const selectRouterQueryParams = createSelector(
  getRouterState,
  state => state.root.queryParams
);
