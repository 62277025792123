<ng-container *rxLet="state$ as state">
  <section id="terms-agreement" data-test-id="user-profile-terms-container">
    <h4 class="font-bold">Terms and conditions</h4>
    <ng-container *ngIf="!state.termsAndConditionsLoading; else loading">
      <ng-container *ngIf="state.termsAndConditions; else nodata">
        <p>
          You can obtain further information on the personal data which 7N stores
          and processes about you by contacting
          <a href="mailto:privacy@7N.com">privacy&#64;7N.com</a>. Further, you may
          object to your personal data being processed or request that your
          personal data is rectified or deleted. If you wish to withdraw your
          consent or have your data rectified or deleted, please contact your 7N
          consultant manager or
          <a href="mailto:privacy@7N.com">privacy&#64;7N.com</a>. Further, if you have
          any complaints about 7N’s processing of your personal data, you may
          contact the Danish Data Protection Agency (<a href="https://www.datatilsynet.dk" target="_blank">www.datatilsynet.dk</a>).
        </p>
        <div class="sliders-container">
          <form [formGroup]="termsForm" data-test-id="user-profile-terms-form">
            <div class="slider">
              <h5 class="font-bold">Event invitations</h5>
              <p>
                From time to time 7N sends out invitations for professional and
                social events and general information regarding 7N to you, if you
                have given your consent to this. You may at any time withdraw your
                consent by either following the unsubscribe link at the bottom of
                those emails or on your my7N user profile.
              </p>
              <mat-slide-toggle
                color="primary"
                formControlName="AcceptMy7nEventInvitation"
                disableRipple="true"
                data-test-id="user-profile-eventInvitation-slider">
                I want to receive 7N event invitations and general information.
              </mat-slide-toggle>
            </div>
            <div class="slider">
              <h5 class="font-bold">Newsletter</h5>
              <p>
                Sign up here to receive monthly e-mails about news and information
                about 7N’s services, products and events. You may withdraw your
                consent to receive newsletters from 7N at any time by following
                the unsubscribe link at the bottom of newsletters or by turning
                off this option. Read more about 7N’s processing of your personal
                data in 7N’s
                <a href="https://www.7N.com/privacy-policy/" target="_blank">Privacy Policy</a>.
              </p>
              <mat-slide-toggle
                color="primary"
                formControlName="AcceptMy7nNewsletter"
                disableRipple="true"
                data-test-id="user-profile-newsletter-slider">
                I want to receive 7N newsletters with news, events and service
                information.
              </mat-slide-toggle>
            </div>
          </form>
        </div>
      </ng-container>
    </ng-container>
  </section>
</ng-container>

<ng-template #nodata>
  <no-data></no-data>
</ng-template>

<ng-template #loading>
  <div class="flex flex-center-start w-full h-full">
    <loader color="primary"></loader>
  </div>
</ng-template>
