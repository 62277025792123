import { Component, Input, OnInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { IMy7nEnvConfig } from '../../../interfaces/my7n-env-config';
import { MY7N_ENV_CONFIG } from '../../../functions/my7n-env-config';
import { IUserPreferences } from '../../../interfaces/user-preferences';
import { FeedbackModalComponent } from '../../feedback-modal/feedback-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthenticationService } from '../../../services/authentication.service';
import { GlobalCommonFacadeService } from '../../../services/facades/global-common-facade.service';
import { ConfirmDialogComponent, ConfirmDialogComponentDefaultConfig, MatDialogService } from '@my7n/ui';
import { LeavingPageDialogConfigData } from '../../../guards/leaving-page.guard';
import { filter, take } from 'rxjs/operators';
import { UserProfileFeatures } from '@my7n/features';
import { GlobalAppConfigFacadeService } from '../../../services/facades/global-app-config-facade.service';

@Component({
  selector: 'navigation-menu',
  templateUrl: './navigation-menu.component.html',
  styleUrls: ['./navigation-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationMenuComponent implements OnInit {
  @Input() user: IUserPreferences;
  leavingPageGuardEnabled: boolean;
  get personalDetailsFeature() {
    return UserProfileFeatures.Default;
  }

  user$ = this.appConfigFacadeService.user$;

  constructor(
    private dialog: MatDialog,
    private authenticationService: AuthenticationService,
    @Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig,
    private globalCommonFacadeService: GlobalCommonFacadeService,
    private appConfigFacadeService: GlobalAppConfigFacadeService,
    private matDialogService: MatDialogService
  ) {}

  ngOnInit() {
    this.globalCommonFacadeService.leavingPageGuardEnabled$.subscribe(
      (isEnabled: boolean) => {
        this.leavingPageGuardEnabled = isEnabled;
      }
    );
  }

  logout() {
    if (this.leavingPageGuardEnabled) {
      // if the global leaving page guard is enabled, show the dialog first
      this.openLeavingPageDialog()
        .afterClosed()
        .pipe(
          take(1),
          filter((leaveBtnPressed: boolean) => leaveBtnPressed === true)
        )
        .subscribe(() => {
          this.globalCommonFacadeService.updateLeavingPageGuardEnabled(false); // reset the flage to avoid browser popup
          this.authenticationService.logout();
        });
    } else {
      this.authenticationService.logout();
    }
  }

  menuClosed() {
    // needed only to trigger change detection when mat-menu emits 'closed'
  }

  currentUserPortraitUrl(): string | null {
    return this.user?.PortraitUrl || null;
  }

  get userName(): string {
    if (this.user) {
      return this.user.FirstName + ' ' + this.user.LastName;
    } else {
      return '';
    }
  }

  openFeedbackDialog() {
    this.dialog.open(FeedbackModalComponent, {
      panelClass: 'feedback-overlay-pane'
    });
  }

  private openLeavingPageDialog(): MatDialogRef<ConfirmDialogComponent, unknown> {
    return this.matDialogService.open(ConfirmDialogComponent, {
      ...ConfirmDialogComponentDefaultConfig,
      data: LeavingPageDialogConfigData
    });
  }
}
