import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import { INavigationItem } from '../../../../interfaces/navigation-item';

@Component({
  selector: 'navigation-content-item',
  templateUrl: './navigation-content-item.component.html',
  styleUrls: ['./navigation-content-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigationContentItemComponent {
  @Input()
  navItem: INavigationItem;

  @Input()
  active: boolean;

  @Output()
  selected$ = new EventEmitter<INavigationItem>();

  get navItemId(): string {
    return 'nav-item-' + this.navItem?.title?.replace(/\s+/g, '')?.toLowerCase();
  }
}
