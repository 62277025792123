import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

// services

@Injectable()
export class CmsItemViewService {
  private _subnavbarComponent$ = new BehaviorSubject<any>(null);

  get subnavbarComponent$(): Observable<any> {
      return this._subnavbarComponent$.asObservable();
  }

  updateSubnavbar(component: any) {
      this._subnavbarComponent$.next(component);
  }
}
