<layout-container>
  <div class="terms-screen" id="terms-screen">
    <form (ngSubmit)="sendAgreement(termsForm)" name="termsForm" [formGroup]="termsForm" [ngClass]="{'loading': loading}" novalidate>
      <div class="terms-screen-container">
        <div class="terms-screen">
          <h2 class="font-bold max-md:typography-sub-header" 
            data-test-id="main-header"
            *ngIf="termsContent.Header">{{ termsContent.Header }}</h2>

          <div class="terms-agreement-content">
            <div class="terms-agreement-content-section"
                 *ngFor="let section of termsContent.Sections">
              <h5 class="section-header font-bold my-0" *ngIf="section.Header">{{ section.Header }}</h5>
              <p *ngFor="let paragraph of section.Paragraphs"
                 [innerHTML]="paragraph"
                 class="section-paragraph"></p>
            </div>
          </div>
          <div class="agreements-checkboxes flex flex-col flex-between-start gap-12">
            <div class="mat-checkbox-container"
                 *ngFor="let checkbox of termsContent.Checkboxes">
              <mat-checkbox color="primary" [formControlName]="checkbox.Name">{{checkbox.Label}}</mat-checkbox>
              <small [innerHTML]="checkbox.SmallText"></small>
              <validation-messages *ngIf="checkbox.Mandatory"
                                   [field]="termsForm.get(checkbox.Name)"
                                   messages="['required']"
                                   [texts]="{ required: checkbox.MandatoryText || 'This checkbox is required.' }">
              </validation-messages>
            </div>
          </div>
          <div class="terms-screen-actions flex flex-start-center">
            <button mat-flat-button color="primary" type="submit" >Save</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</layout-container>
