import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { IMy7nEnvConfig } from '../interfaces/my7n-env-config';
import { PRIMARY_OUTLET, Router, UrlTree} from '@angular/router';
import { IBackLink } from '../interfaces/back-link';

const HISTORY_SIZE = 100;

@Injectable({
  providedIn: 'root'
})
export class AppHistoryService {

  private subject = new BehaviorSubject<string>(null);
  private history: Array<string> = [];
  private backLink: string = null;

  constructor(@Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig,
              private router: Router) {}

  get historyChange$(): Observable<string> {
    return this.subject.asObservable();
  }

  /**
   * Adds history entry with previous and current link.
   * Checks if user has moved back or forward to current location, and eliminates possible duplicates in history (avoids loops)
   * @param {string} fromUrl
   * @param {string} toUrl
   */
  addEntry(fromUrl: string, toUrl: string) {
    const APPLICATION_URL = this.envConfig.APPLICATION_URL;
    const history = this.history;
    const toUrlWithoutAppUrl = AppHistoryService.cleanUrl(toUrl.replace(APPLICATION_URL, ''));

    if (history[0] && history[0] === toUrlWithoutAppUrl) {
      // do nothing, redirecting to the same url
    } else if (this.backLink && this.backLink === toUrlWithoutAppUrl) {
      // Back in history,
      // [1] should be the current page and [2] should be the back link, instead we drop the [0] entry
      // and use [1] as backlink
      history.shift();
      this.backLink = history[1] || null;
    } else {
      // First entry becomes backlink
      this.backLink = history[0] || null;
      // Add toUrl as last history entry
      history.unshift(toUrlWithoutAppUrl);

      if (history.length >= HISTORY_SIZE) {
        history.pop();
      }
    }

    this.subject.next(this.backLink);
  }

  /**
   * Checks if backlink starts with given string, in case it doesn't uses default value if it is set
   * @param {string} urlStartsWith
   * @param {string} [defaultValue]
   * @returns {{routerLink:Array<string>, queryParams:Object, isDefault?:boolean}}
   */
  backlinkWithFallback(urlStartsWith: string, defaultValue?: string): IBackLink  {
    const link = this.backLink;
    let backlinkObject: IBackLink;

    if (link && link.indexOf(urlStartsWith) === 0) {
      backlinkObject = AppHistoryService.buildRouterLinkArray(this.router.parseUrl(link)) as IBackLink;
      backlinkObject.isDefault = false;
    } else {
      if (!defaultValue) {
        console.warn('[AppHistoryService] missing defaultValue in backLinkWithFallback, using last known url');
      }
      backlinkObject = AppHistoryService.buildRouterLinkArray(this.router.parseUrl(defaultValue ? defaultValue : link)) as IBackLink;
      backlinkObject.isDefault = true;
    }

    backlinkObject.fromHistory = link !== null;

    return backlinkObject;
  }

  /**
   * Removes ? and & characters which are doubled or dangling
   * Helps keeping unified urls in history
   * @param {string} url
   * @returns {string}
   */
  private static cleanUrl(url: string) {
    return url
      // Replace all queryParam starting with _
      .replace(/_[a-zA-Z]+=[^&]+/g, '')
      .replace('?&', '?')
      .replace(/[&]{2,}/g, '&')
      .replace(/[&?]$/, '');
  }

  /**
   * Helpers method to use in routerLink
   * @param {UrlTree} urlTree
   * @returns {{routerLink: Array<string>; queryParams: Object}}
   */
  private static buildRouterLinkArray(urlTree: UrlTree): Partial<IBackLink> {
    return {
      routerLink: [
        '/' + urlTree.root.children[PRIMARY_OUTLET].toString()
      ],
      queryParams: urlTree.queryParams
    };
  }

}
