import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  EventEmitter,
  Output,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { EventsService } from '../../../services/events.service';
import { IEventBanner } from '../../../interfaces/event-banner';
import { UntypedFormGroup } from '@angular/forms';
import {  takeUntil, distinctUntilChanged } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ImageProcessing } from '../../../classes/image-processing';
import { MediaQueryService } from '@my7n/ui';

@Component({
  selector: 'image-picker',
  templateUrl: './image-picker.component.html',
  styleUrls: ['./image-picker.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [EventsService]
})
export class ImagePickerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() presetSelected = new EventEmitter<string>();
  @Output() customImageSelected = new EventEmitter<string>();

  /**
   * Reference to the parent form element
   */
  @Input() formElement: UntypedFormGroup;

  /**
   * Id of preset image.
   */
  @Input() selectedImage: string;

  /**
   * Thumb for preset image.
   */
  @Input() selectedImageThumbUrl: string;

  @Input() uploadedImage;

  /**
   * Flag indicates whether selected image is preset image or not.
   */
  @Input() isPreset;

  @ViewChild('presetList') presetList: ElementRef;
  @ViewChild('pickerSlider') pickerSlider: ElementRef;
  @ViewChild('presetEl') galleryContainer: ElementRef;

  destroySubject$ = new Subject<void>();

  selfInstance: ImagePickerComponent;

  bannerList: Array<IEventBanner>;
  banners: Array<Array<IEventBanner>>;

  itemWidth: number;
  private lastSlideItemsCount: number;

  private config = {
    itemsOnPage: 4,
    itemsOnPageMobile: 2,
    itemsPadding: 6,
    itemHeight: 86
  };

  itemsOnPage = 4;

  slideRowsNumber = 2;

  currentSlide = 0;
  disableSlider = false;
  slidesNumber = null;
  selectedImageIndex: number;
  isHoveredEventBannerItem: IEventBanner;

  constructor(
    private eventService: EventsService,
    public mediaQueryService: MediaQueryService,
    private changeDetectionRef: ChangeDetectorRef,
  ) {
    this.selfInstance = this;
  }

  get uploadGroup(): UntypedFormGroup {
    return this.formElement.controls['upload'] as UntypedFormGroup;
  }

  ngOnInit() {
    this.mediaQueryService
      .breakpoints$
      .pipe(
        takeUntil(this.destroySubject$),
        distinctUntilChanged()
      )
      .subscribe(({ ltMd }) => {
        if(ltMd) {
          this.updateSlider(true)
        } else {
          this.updateSlider();
        }
        ;
      });

    // Creates dataUrl for uploaded banner and passes it to custom-upload
    // this is required here to send preset image as custom uploaded and satisfy backend validation for base 64.
    // We cannot send presetId instead because we don't get it in the initial backend request for Event details
    if (this.uploadedImage) {
        ImageProcessing.shrinkImageToWidth(this.uploadedImage).then(
          (dataUrl) => {
            this.uploadedImage = dataUrl;
            this.customImageSelected.emit(dataUrl);
          }
        );
      }
  }

  private updateSlider(mobile = false) {
    if (this.bannerList) {
      this.slideRowsNumber = mobile ? 1 : 2;
      this.itemsOnPage = mobile
        ? this.config.itemsOnPageMobile
        : this.config.itemsOnPage;
      this.banners = this.get2dGallery(this.slideRowsNumber, this.bannerList);
      this.prepareSlider();
    }
  }

  isNotSelectedState(image: IEventBanner): boolean {
    if (image && this.selectedImageIndex !== undefined) {
      return (
        this.bannerList[this.selectedImageIndex].PresetId !== image.PresetId
      );
    } else {
      return false;
    }
  }

  isSelected(image: IEventBanner): boolean {
    if (image && this.selectedImageIndex !== undefined) {
      return (
        this.bannerList[this.selectedImageIndex].PresetId === image.PresetId
      );
    } else {
      return false;
    }
  }

  getEventBannerActive(banner: IEventBanner): boolean {
    if (
      this.isHoveredEventBannerItem &&
      this.isHoveredEventBannerItem.PresetId === banner.PresetId
    ) {
      return true;
    }

    if (
      !this.isHoveredEventBannerItem &&
      this.selectedImageIndex !== undefined &&
      this.selectedImageIndex !== null &&
      this.selectedImageIndex !== -1 &&
      this.bannerList[this.selectedImageIndex].PresetId === banner.PresetId
    ) {
      return true;
    }

    if (
      !this.isHoveredEventBannerItem &&
      (!this.selectedImageIndex || this.selectedImageIndex === -1)
    ) {
      return true;
    }

    return false;
  }

  onEventBannerOver(banner: IEventBanner) {
    this.isHoveredEventBannerItem = banner;
  }

  onEventBannerLeave() {
    this.isHoveredEventBannerItem = null;
  }

  ngAfterViewInit() {
    this.eventService.getBanners().then((data) => {
      this.bannerList = data;
      this.banners = this.get2dGallery(this.slideRowsNumber, this.bannerList);
      window.setTimeout(() => this.prepareSlider());
    });
  }

  prepareSlider() {
    if (this.isPreset) {
      // search inside 2d array
      for (let i = 0; i < this.banners.length; i++) {
        for (let j = 0; j < this.banners[i].length; j++) {
          if (this.banners[i][j].PresetUrl === this.selectedImageThumbUrl) {
            this.currentSlide = Math.floor(j / this.itemsOnPage);
          }
        }
      }
      // needed for item-active class
      this.recalculateSelectedImageIndex();
      this.presetSelected.emit(
        this.bannerList[this.selectedImageIndex].PresetId
      );
    }
    if (this.pickerSlider && this.banners) {
      this.itemWidth =
        this.pickerSlider.nativeElement.offsetWidth / this.itemsOnPage -
        (this.config.itemsPadding * (this.itemsOnPage - 1)) / this.itemsOnPage;
      // this.itemsOnPage is 2 images for desktop and 1 for mobile. First we calculate how many images left for last
      // slide and then determinate how many items (2 for dektop/ 1 for mobile) is it
      this.lastSlideItemsCount = Math.ceil(
        (this.bannerList.length % (this.itemsOnPage * this.slideRowsNumber)) /
          this.slideRowsNumber
      );
      this.slidesNumber = Math.ceil(
        this.bannerList.length / this.itemsOnPage / this.slideRowsNumber
      );
      this.pickerSlider.nativeElement.style.height =
        (this.config.itemHeight + this.config.itemsPadding) *
          this.banners.length -
        this.config.itemsPadding +
        'px';

      this.presetList.nativeElement.style.width =
        this.itemWidth * this.bannerList.length + 'px';

      this.setSlide();
    }
    this.changeDetectionRef.markForCheck();
  }

  /**
   * Displays next page of preset thumbnails.
   */
  nextSlide() {
    if (this.currentSlide < this.slidesNumber - 2) {
      this.currentSlide++;
      this.presetList.nativeElement.style.left = this.getListPosition() + 'px';
    } else if (this.currentSlide < this.slidesNumber - 1) {
      this.currentSlide++;
      this.presetList.nativeElement.style.left =
        this.getListPosition() +
        (this.lastSlideItemsCount * this.itemWidth +
          this.config.itemsPadding * this.lastSlideItemsCount) +
        'px';
    }
    this.changeDetectionRef.markForCheck();
  }

  /**
   * Displays prev page of preset thumbnails.
   */
  prevSlide() {
    if (this.currentSlide >= 1) {
      this.currentSlide--;
      this.presetList.nativeElement.style.left = this.getListPosition() + 'px';
      this.changeDetectionRef.markForCheck();
    }
  }

  setSlide() {
    this.presetList.nativeElement.style.left = this.getListPosition() + 'px';
  }

  /**
   * Selects preset with specified index.
   * @param {number} index
   */
  selectImage(banner: IEventBanner) {
    if (banner) {
      this.selectedImageIndex = this.bannerList
        .map((item) => item.PresetId)
        .indexOf(banner.PresetId);
      this.selectedImage = this.bannerList[this.selectedImageIndex].PresetId;

      // emits event with selected preset value
      this.presetSelected.emit(this.selectedImage);
    }
  }

  /**
   * Clears selected image.
   */
  resetSelectedImage() {
    this.selectedImageIndex = -1;
    this.selectedImage = null;
    this.uploadedImage = null;
    this.presetSelected.emit(null);
  }

  /**
   * Handler for custom image upload.
   *
   * @param {string} imageData Image data encoded by base64.
   */
  onCustomImageUpload(imageData: string) {
    this.uploadedImage = imageData;
    this.recalculateSelectedImageIndex();
    this.customImageSelected.emit(imageData);
  }

  private get2dGallery(
    rows: number,
    elements: Array<IEventBanner>
  ): Array<Array<IEventBanner>> {
    let row: Array<IEventBanner> = [];
    const gallery2d: Array<Array<IEventBanner>> = [];
    if (elements) {
      const itemsNo = elements.length;
      let rowSize = 0;
      if (itemsNo > 1 && rows > 0) {
        rowSize = Math.ceil(itemsNo / rows);
        for (let i = 0; i < rows; i++) {
          gallery2d[i] = elements.slice(i * rowSize, i * rowSize + rowSize);
        }
      } else {
        row = [elements[0]];
        gallery2d[0] = elements.slice();
      }
    }

    return gallery2d;
  }

  private getListPosition(): number {
    return (
      -this.currentSlide *
      (this.itemsOnPage * this.itemWidth +
        this.config.itemsPadding * this.itemsOnPage)
    );
  }

  private recalculateSelectedImageIndex() {
    if (this.bannerList) {
      this.selectedImageIndex = this.bannerList.findIndex(
        (item) => item.PresetUrl === this.selectedImageThumbUrl
      );
    } else {
      this.selectedImageIndex = -1;
    }
  }

  ngOnDestroy(): void {
    this.destroySubject$.next();
  }
}
