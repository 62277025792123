import {Injectable} from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import {ServiceNames} from '../../interfaces/my7n-env-config';
import {Observable} from 'rxjs';
import {ICvParseIdResponse} from '../../interfaces/cv';
import {AppConfigService} from '../app-config.service';

@Injectable()
export class CvParseService {
  readonly CV_V1_API: string;
  constructor(private http: HttpClient,
              private appConfigService: AppConfigService) {
    this.CV_V1_API = appConfigService.serviceUrl(ServiceNames.Cv, 'v1') + 'cv/';
  }

  /**
   * Load CV file for existing CV.
   * CV will be parsed and detected data will be added to existing CV
   * @param cvId
   * @param cvFile
   */
  parseCvFile(cvId: number, cvFile: File): Observable<HttpResponse<void>> {
    const formData: FormData = new FormData();
    formData.append('cvFile', cvFile);
    return this.http.post<void>(`${this.CV_V1_API}${cvId}/parse-async`, formData, { observe: 'response' });
  }

  /**
   * Parse given cv file and create new CV based on detected data.
   * CV will be created for specific user
   * @param userId
   * @param cvFile
   */
  createCvFromFile(userId: string, cvFile: File): Observable<HttpResponse<number>> {
    const formData: FormData = new FormData();
    formData.append('cvFile', cvFile);
    return this.http.post<number>(`${this.CV_V1_API}${userId}/parse-async`, formData, { observe: 'response' });
  }

  /**
   * Parsing status for user's CV
   */
  getParsingStatus(): Observable<ICvParseIdResponse> {
    return this.http.get<ICvParseIdResponse>(this.CV_V1_API + 'parsing-status');
  }

  /**
   * Get parsing result for specific CV
   */
  getParsingResult(cvId: number): Observable<HttpResponse<void>> {
    return this.http.get<void>(`${this.CV_V1_API}${cvId}/parsing-result`, { observe: 'response' });
  }
}
