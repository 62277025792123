import { Component, Input } from '@angular/core';
import { INewsAttachment } from '../../../../../interfaces/news-attachment';

@Component({
  selector: 'gallery-thumb-item',
  templateUrl: './gallery-thumb-item.component.html',
  styleUrls: ['./gallery-thumb-item.component.scss']
})
export class GalleryThumbItemComponent {
  @Input() thumbItem: INewsAttachment;

  @Input() showAllLayer = false;

  @Input() newsId: number;

  @Input() moreCount: number;
}
