import { Inject, Injectable } from '@angular/core';
import { MY7N_ENV_CONFIG } from '../functions/my7n-env-config';
import { IMy7nEnvConfig } from '../interfaces/my7n-env-config';

@Injectable()
export class GTMService {
  constructor(@Inject(MY7N_ENV_CONFIG) private envConfig: IMy7nEnvConfig) {}

  init() {
    const windowRef = window as any;
    const gtmScript = document.createElement('script');
    windowRef.dataLayer = windowRef.dataLayer || [];
    windowRef.gtag = function() {
      // eslint-disable-next-line prefer-rest-params
      windowRef.dataLayer.push(arguments);
    }

    gtmScript.id = 'GTMscript';
    gtmScript.async = true;
    gtmScript.src = `https://www.googletagmanager.com/gtag/js?id=${this.envConfig.GOOGLE_TAG_MANAGER_ID}`;

    document.head.appendChild(gtmScript);

    gtmScript.addEventListener('load', () => {
      (windowRef.gtag as any)('js', new Date());
      (windowRef.gtag as any)('config', this.envConfig.GOOGLE_TAG_MANAGER_ID);
    });
  }
}
