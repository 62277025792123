import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum Locales {
  PL = 'pl',
  EN = 'en-US'
}

export interface Lang {
  code: string;
  name: string;
  locale: string;
}

export interface LangMap {
  langNavigatorCode: string;
  locale: Locales;
}

export interface Metatags {
  desc: string;
  title: string;
  keywords: string;
}

@Injectable()
export class ContentfulSettingsService {
  private readonly langs: Lang[] = [
    {
      code: 'pl',
      name: 'polish',
      locale: Locales.PL
    },
    {
      code: 'en',
      name: 'english',
      locale: Locales.EN
    }
  ];

  private readonly langsMap: LangMap[] = [
    {
      langNavigatorCode: 'pl',
      locale: Locales.PL
    },
    {
      langNavigatorCode: 'pl-pl',
      locale: Locales.PL
    },
    {
      langNavigatorCode: 'en',
      locale: Locales.EN
    },
    {
      langNavigatorCode: 'en-gb',
      locale: Locales.EN
    },
    {
      langNavigatorCode: 'en-US',
      locale: Locales.EN
    }
  ];

  private lang$ = new BehaviorSubject<Lang>(this.langs[0]);

  private readonly defaultLocale = Locales.EN;

  getLocale(): string {
    return this.lang$.value.locale;
  }

  getLangCode(): string {
    return this.lang$.value.code;
  }

  getLanguages(): Lang[] {
    return this.langs.slice();
  }

  setCurrentLangByLangCode(langCode: string): void {
    const l: Lang = this.langs.find(
      (item) => item.code === langCode.toLowerCase()
    );
    if (l) {
      this.lang$.next(l);
    }
  }

  setCurrentLangByNavigatorLang(
    langFromNavigator: string,
    langsArrayFromNavigator: string[]
  ): void {
    // first try to search using langFromNavigator
    if (langFromNavigator && langFromNavigator.length > 0) {
      const locale = this.searchLangsMap(langFromNavigator);
      if (locale) {
        this.setCurrentLangByLocale(locale);
        return;
      }
    }

    // if not found try with the langs array
    if (langsArrayFromNavigator && langsArrayFromNavigator.length > 0) {
      let itemFound = false;
      langsArrayFromNavigator.forEach((item: string) => {
        const locale = this.searchLangsMap(item);
        if (locale && !itemFound) {
          this.setCurrentLangByLocale(locale);
          itemFound = true;
        }
      });
      if (itemFound) {
        return;
      }
    }

    // if nothing was found set to default
    this.setCurrentLangByLocale(this.defaultLocale);
  }

  private searchLangsMap(l: string): string {
    // returns locale from langsMap
    let mapping: LangMap = null;
    mapping = this.langsMap.find(
      (item: LangMap) =>
        item.langNavigatorCode.toLowerCase() === l.toLowerCase()
    );

    if (mapping) {
      return mapping.locale;
    } else {
      return null;
    }
  }

  setCurrentLangByLocale(locale: string): void {
    const l: Lang = this.langs.find((item) => item.locale === locale);
    if (l) {
      this.lang$.next(l);
    }
  }

  getLocaleByLangCode(langCode: string): string {
    const l: Lang = this.langs.find(
      (item) => item.code === langCode.toLowerCase()
    );
    if (l) {
      return l.locale;
    } else {
      return this.getLocale();
    }
  }

  getCurrentLang(): Observable<Lang> {
    return this.lang$.asObservable();
  }
}
