/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpErrorResponse } from '@angular/common/http';
import {
  documentToHtmlString,
  Options
} from '@contentful/rich-text-html-renderer';
import { EntryCollection } from 'contentful';
import { default as moment } from 'moment';
import { ICmsAuthor } from '../../interfaces/cms';
import {
  ICmsNewsList,
  ICmsNewsViewItem,
  INewsBase,
  NewsTypes
} from '../../interfaces/news';
import { ITimelineCmsNewsResponse } from '../../interfaces/news-response';

export class NewsHelper {
  static sortNewsByDate(newsOne: INewsBase, newsTwo: INewsBase) {
    const aDate = moment(newsOne.Published);
    const bDate = moment(newsTwo.Published);
    return moment(bDate).diff(aDate);
  }

  static getNewsListHandler(
    newsEntries: EntryCollection<any>,
    sectionRendererOptions: Partial<Options>
  ): ITimelineCmsNewsResponse {
    const results: Array<ICmsNewsList> = newsEntries.items.map((entry) => {
      return {
        Id: entry.sys.id,
        Type: NewsTypes.CMS_NEWS,
        Title: entry.fields.title,
        Description: documentToHtmlString(
          entry.fields.description,
          sectionRendererOptions
        ),
        Image: (entry.fields as any)?.image?.fields?.file?.url
          ? (entry.fields as any)?.image?.fields?.file?.url
          : null,
        VideoUrl: entry.fields.videoUrl,
        VideoThumbnail: (entry.fields as any)?.videoThumbnail?.fields?.file?.url,
        AuthorAvatar: ((entry.fields.author as any)?.fields as any)?.avatar.fields.file.url,
        AuthorJobTitle: '',
        AuthorName: ((entry.fields.author as any)?.fields as ICmsAuthor)?.fullName,
        Countries: entry.fields.countries,
        Published: entry.fields.newsDate,
        GalleryUrl: entry.fields.galleryUrl
      };
    });

    return {
      news: results,
      total: newsEntries.total
    };
  }

  static getNewsViewHandler(
    newsEntries: EntryCollection<any>,
    sectionRendererOptions: Partial<Options>
  ): ICmsNewsViewItem {
    const results: Array<ICmsNewsViewItem> = newsEntries.items.map((entry) => {
      return {
        id: entry.sys.id,
        title: entry.fields.title,
        content: documentToHtmlString(
          entry.fields.description as any,
          sectionRendererOptions
        ),
        headerImageUrl: (entry.fields as any)?.image?.fields?.file?.url
          ? (entry.fields as any)?.image?.fields?.file?.url
          : null,
        videoUrl: entry.fields.videoUrl,
        videoThumbnail: entry.fields.videoThumbnail,
        author: {
          id: (entry.fields.author as any)?.sys.id,
          avatar: ((entry.fields.author as any)?.fields as any)?.avatar?.fields.file.url,
          fullName: ((entry.fields.author as any)?.fields as ICmsAuthor)?.fullName
        },
        countries: entry.fields.countries,
        creationDate: (entry.fields as any)?.newsDate
          ? new Date((entry.fields as any)?.newsDate as string)
          : null,
        galleryUrl: entry.fields.galleryUrl,
        tags: [] // @TODO update when tags supported
      };
    });

    if (results.length === 0) {
      throw new HttpErrorResponse({ status: 404, statusText: 'Not found' });
    }

    return results[0];
  }
}
