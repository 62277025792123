<button mat-button
        [matMenuTriggerFor]="menu"
        class="user-info flex"
        [disableRipple]="true">
  <secure-avatar [avatarUrl]="currentUserPortraitUrl()"
                 [title]="userName"></secure-avatar>
  <i class="navbar-profile-caret icon icon-caret"></i>
</button>
<mat-menu #menu="matMenu"
          xPosition="before"
          yPosition="below"
          class="context-menu"
          (closed)="menuClosed()">
  <ng-container *ngIf="user$ | async">
   <span mat-menu-item
         disableRipple
         class="context-user-info flex"
         *ngIf="user">
   <secure-avatar [avatarUrl]="currentUserPortraitUrl()"
                  [title]="userName"></secure-avatar>
   <span class="menu-button-label user-name">{{ userName }}</span>
   </span>
   <a mat-menu-item
      class="button-personal-details-button"
      *authCan="personalDetailsFeature"
      [routerLink]="'/profile/details'">Personal details</a>
   <a mat-menu-item
      class="button-feedback-button"
      *ngIf="user"
      (click)="openFeedbackDialog()">Send feedback</a>
   <a mat-menu-item
      class="button-logout-button"
      color="warn"
      (click)="logout()">Log out</a>
  </ng-container>
</mat-menu>
