import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Action, Store } from '@ngrx/store';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  take,
  tap,
  withLatestFrom,
  delay
} from 'rxjs/operators';
import * as GlobalActions from '../actions/global.actions';
import { IAppConfig } from '../../interfaces/app-config';
import { BusinessUnit } from '../../interfaces/business-unit';
import { ApplicationInsightsService } from '../../services/application-insights.service';
import { SeverityLevel } from '../../interfaces/my7n-env-config';
import { UserService } from '../../services/user.service';
import { IBlobSasTokens } from '../../interfaces/blob-sas';
import { default as moment } from 'moment';
import { AuthenticationService } from '../../services/authentication.service';

import * as fromGlobal from '../reducers/global/index';
import * as GlobalAppConfigSelectors from '../reducers/global/global-app-config.selectors';

@Injectable()
export class GlobalCommonEffects {
  private _buFromTokenClaims = new BehaviorSubject<[BusinessUnit, string]>([
    null,
    null
  ]);

  private _buFromUserEndpoint = new Observable<BusinessUnit>();

  constructor(
    private actions$: Actions,
    private appInsights: ApplicationInsightsService,
    private userService: UserService,
    private authenticationService: AuthenticationService,
    private store$: Store<fromGlobal.GlobalModuleState>
  ) {
    this._buFromUserEndpoint = this.store$.select(GlobalAppConfigSelectors.selectAppConfig).pipe(
      filter((appConfig: IAppConfig) => !!appConfig),
      map((appConfig) => appConfig.User.BusinessUnit as BusinessUnit),
      filter(bu => !!bu)
    );

    // business unit check (token vs API)
    this._buFromUserEndpoint
      .pipe(
        withLatestFrom(this._buFromTokenClaims),
        distinctUntilChanged(),
        filter(
          (businessUnits: [BusinessUnit, [BusinessUnit, string]]) =>
            businessUnits[0] &&
            businessUnits[1][0] &&
            businessUnits[0] !== businessUnits[1][0] // different BUs
        ),
        take(1)
      )
      .subscribe((businessUnits: [BusinessUnit, [BusinessUnit, string]]) => {
        try {
          this.appInsights.trackTrace(
            'BU from id token not equal BU from API',
            {
              Frontend: 'true',
              Service: 'frontend-core',
              ApiBu: businessUnits[0],
              TokenBu: businessUnits[1][0],
              Upn: businessUnits[1][1]
            },
            SeverityLevel.Warning
          );
        } catch (e) {
          console.debug(
            '[GlobalEffects] Cannot send BUs check logs',
            (e as Error).message
          );
        }
      });
  }

  updateTokenClaims$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalActions.updateTokenClaims),
      distinctUntilChanged(),
      tap((action: any) => {
        this._buFromTokenClaims.next([
          action.tokenClaims?.BusinessUnit,
          action.tokenClaims?.upn
        ]);
      })
    );
  });

  queryBlobSasTokens$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalActions.queryBlobSasTokens),
      concatLatestFrom(() => [this.authenticationService.authenticated$]),
      filter(([action, isAuthenticated]) => isAuthenticated),
      distinctUntilChanged(),
      switchMap(() => {
        return this.userService.getBlobSasTokens().pipe(
          map((res: IBlobSasTokens) => {
            return GlobalActions.queryBlobSasTokensSuccess({
              blobSasTokens: res
            });
          }),
          catchError((err) => {
            console.error('[Global Effects] Failed to get blobSasTokens', err);

            return of(GlobalActions.queryBlobSasTokensError());
          })
        );
      })
    );
  });

  queryBlobSasTokensSuccess$: Observable<Action> = createEffect(() => {
    return this.actions$.pipe(
      ofType(GlobalActions.queryBlobSasTokensSuccess),
      distinctUntilChanged(),
      switchMap((token) => {
        const expDate = moment(token.blobSasTokens.ExpirationDate);
        const currentDate = moment();
        const timeToExpire = expDate.diff(currentDate);

        return of(GlobalActions.queryBlobSasTokens()).pipe(delay(timeToExpire));
      })
    );
  });
}
