import { Component, OnInit, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { delay, map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'system-messages',
  templateUrl: './system-messages.component.html',
  styleUrls: ['./system-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SystemMessagesComponent implements OnInit {
  private readonly IS_OFFLINE_DELAY = 2000;

  @HostListener('window:offline')
  handleOffline() {
    this._isOnlineSubject.next(false);
  }

  @HostListener('window:online')
  handleOnline() {
    this._isOnlineSubject.next(true);
  }

  private _isOnlineSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  get isOnline$(): Observable<boolean> {
    return this._isOnlineSubject.asObservable();
  }

  showOfflineMessage$: Observable<boolean>;

  ngOnInit(): void {
    this.showOfflineMessage$ = this.isOnline$.pipe(
      switchMap((isOnline: boolean) => of(isOnline).pipe(delay(isOnline ? 0 : this.IS_OFFLINE_DELAY))),
      map(isOnline => !isOnline)
    );
  }
}
