import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit
} from '@angular/core';
import { ICheckboxCategoryTreeItem } from '../../../interfaces/checkbox-category-tree-item';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';

interface ILetterHeader {
  letter: string;
  firstIndex: number;
}

@Component({
  selector: 'checkboxes-categories-edit',
  templateUrl: './checkboxes-categories-edit.component.html',
  styleUrls: ['./checkboxes-categories-edit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxesCategoriesEditComponent implements OnInit {
  @Input() checkboxes: Array<ICheckboxCategoryTreeItem>;
  @Input() name: string;
  @Input() parent: UntypedFormGroup | UntypedFormArray;

  @Input() instance: string;

  @Input() showLetterHeaders = false;
  @Input() isFlat = false;
  @Input() columns: number;
  @Input() disableSorting = false;
  @Input() enableHidingItems = true;

  headers: Array<ILetterHeader>;

  ngOnInit() {

    if(this.enableHidingItems) {
      this.filterCheckboxesByVisibility();
    }
    
    if (!this.disableSorting) {
      this.initLetterHeaders(this.checkboxes);
    }

    if (!this.instance) {
      // Random instance key
      this.instance = Math.random().toString(36).substr(2, 5);
    }
  }

  getItemsByHeader(header: string): Array<ICheckboxCategoryTreeItem> {
    return this.checkboxes.filter(
      (item) => item.Name[0].toUpperCase() === header
    );
  }

  private initLetterHeaders(list: Array<ICheckboxCategoryTreeItem>) {
    this.headers = [];

    if (list && list.length > 0) {
      let index = 0;
      let lastLetter = '';
      list.sort((a, b) => a.Name.localeCompare(b.Name));
      list.forEach((item) => {
        if (lastLetter !== item.Name[0].toUpperCase()) {
          this.headers.push({
            letter: item.Name[0].toUpperCase(),
            firstIndex: index
          });
          lastLetter = item.Name[0].toUpperCase();
        }
        index++;
      });
    }
  }

  isColumnBreakBefore(index: number): boolean {
    const columnBreakFactor =
      Math.ceil(this.checkboxes.length / this.columns) + 1;
    return (index + 1) % columnBreakFactor === 0;
  }

  private filterCheckboxesByVisibility() {
    // We want to filter out items with flag IsVisible set to false and only
    // when this flag is set explicitly to false (some dictionaries doesn't have
    // filtering by this property). Moreover even if IsVisible is set to false,
    // we want to keep this item if it is checked (for users who have already selected this item).

    this.checkboxes = this.checkboxes.filter(
      (item) =>
        item.IsVisible !== false ||
        (item.IsVisible === false && item.Checked === true)
    );
  }
}
