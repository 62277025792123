import {AbstractControl} from "@angular/forms";

// @FIXME recurrensive implementation, change for deep trees;
function deepCountTrue(values, sum: number, limit) {
  const len = values.length;

  // If required sum is reached faster, no need to travel further
  if (sum >= limit) {
    return sum;
  }

  for(let i = 0; i < len; i++) {
    sum += values[i].checkbox ? 1 : 0;

    if (sum >= limit) {
      return sum;
    }

    if (values[i].subcategories && values[i].subcategories.length > 0) {
      sum = deepCountTrue(values[i].subcategories, sum, limit)
    }
  }

  return sum;
}

export const CheckboxRequiredValidator = (numberOfRequiredCheckboxes = 1) => {
  return (control: AbstractControl): {[key: string]: boolean} => {
    if (deepCountTrue(control.value, 0, numberOfRequiredCheckboxes) < numberOfRequiredCheckboxes) {
      return { noRequiredCheckboxCount: true }
    }

    return null;
  };
};


