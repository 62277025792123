import { IAddress } from './address';
import { ILocation } from './location';
import { IPhones } from './phones';

export interface IUserProfile {
  AcceptedPublicationDataOnWebsiteBy7n: boolean;
  AcceptedStoringAndSendingDataBy7n: boolean;
  AcceptedMy7nEventInvitation: boolean;
  AcceptedMy7nNewsletter: boolean;
  Address: IAddress;
  BusinessNumber: string;
  CellNumber: string;
  CompanyName: string;
  CompanyWebsite: string;
  Email: string;
  HomeNumber: string;
  Locations: ILocation[];
  SkypeId: string;
}

export interface IPersonalDetails {
  FirstName: string;
  LastName: string;
  Email: string;
  Phones: IPhones;
  Address: IAddress;
}

export interface IPersonalDetailsResponse {
  FirstName: string;
  LastName: string;
  Email: string;
  MobilePhone: string;
  BusinessPhone: string;
  Address: IAddress;
}

export interface ITermsAndConditions {
  AcceptStoringAndSendingDataBy7n: boolean;
  AcceptMy7nEventInvitation: boolean;
  AcceptMy7nNewsletter: boolean;
  My7nConsents: boolean;
}

export interface IBusinessDetails {
  CompanyName: string;
  CompanyWebsite: string;
}

export interface IUpdateAvatarResponse {
  PortraitUrl: string;
}

export const MAX_LENGTHS = {
  email: 100,
  skype: 100,
  company_name: 100,
  website: 100
};

export interface IConsultantsTenantDetails {
  IsActive: boolean;
  PrivateMail: string;
  ConsultantUPN: string;
}
