import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ICheckboxCategoryTreeItem } from '../../../interfaces/checkbox-category-tree-item';

@Component({
  selector: 'checkboxes-categories-details',
  templateUrl: './checkboxes-categories-details.component.html',
  styleUrls: [ './checkboxes-categories-details.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckboxesCategoriesDetailsComponent implements OnChanges {
  @Input() mainItemsNumber: number = Number.MAX_SAFE_INTEGER;
  @Input() sortByOrder = false;
  @Input()
  set checkboxes(checkboxes: Array<ICheckboxCategoryTreeItem>) {
    if (Array.isArray(checkboxes)) {
      this._checkboxes = checkboxes.filter(CheckboxesCategoriesDetailsComponent.filterOnlyChecked);
    } else {
      this._checkboxes = [];
    }
  }

  get checkboxes() {
    return this._checkboxes;
  }

  private _checkboxes: Array<ICheckboxCategoryTreeItem>;

  ngOnChanges(changes: SimpleChanges) {
    const shouldSort = (changes.checkboxes && this.sortByOrder) || (changes.sortByOrder && changes.sortByOrder.currentValue);

    if (shouldSort) {
          this._checkboxes.sort(CheckboxesCategoriesDetailsComponent.sortCheckboxes);
    }
  }

  trackById(index: number, checkbox: ICheckboxCategoryTreeItem) {
    return checkbox.Id;
  }

  private static filterOnlyChecked(element) {
    return element.Checked === true;
  }

  private static sortCheckboxes = (a: ICheckboxCategoryTreeItem, b: ICheckboxCategoryTreeItem) => {
    return a.Order - b.Order;
  }
}
